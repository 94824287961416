///<reference path="../../_services/meets.service.ts"/>
import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {MeetsService} from "../../_services/meets.service";
import {TranslateService} from "../../translate/translate.service";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DialogService} from "ng2-bootstrap-modal";
import {PagerService} from "../../_services/pager.service";
import {Subscription} from "rxjs";

@Component({
  templateUrl: './meet-voting.component.html'
})
export class MeetVotingComponent implements OnInit {
  private crtResult: any;
  private searchFocused: boolean = false;
  sub: Subscription;
  private page: { title: string };
  private meetId: number;
  private found: any;
  private searchTerm: string;
  private isBad: boolean;

  private searchQrForm: FormGroup;
  private bulletinForm: FormGroup;
  private answersArray: FormArray;
  private crtOption: any;
  private bulletin: any;
  private registration: any;
  private notFound: boolean;
  private bulletinVoted: boolean;

  constructor(
    private route: ActivatedRoute, private _translate: TranslateService,
    public fb: FormBuilder, private meetsService: MeetsService,
    private dialogService: DialogService, private pagerService: PagerService
  ) {
    this.isBad = false;
    this.page = { title: this._translate.instant("meet.voting.title") };
    this.notFound = false;
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.meetId = params['id'];
    });
    this.searchQrForm=this.fb.group({
      searchField: ['', Validators.required]
    });
    this.answersArray=this.fb.array([
    ]);
    this.bulletinForm = this.fb.group({
      formArray: this.answersArray,
      isBad: []
    })
    this.updateCrtResults();
  }

  get self()  {
    return this;
  }

  onSearchQr() {
    let findQuery=this.searchQrForm.value;
    let self=this;
    this.registration=null;
    this.searchTerm=findQuery.searchField;
    this.searchQrForm.reset();

    this.meetsService.findVoteQr(findQuery, this.meetId)
      .subscribe(data => {
        if(data.status==200) {
          let found=data.object;
          let message=data.message;
          if(message=="meetRegistrationBulletin") {
            self.bulletin=found.object;
            self.crtOption=null;
            self.registration=found.registrationDb;
          } else if(message=="meetRegistrationBulletinOption") {
            self.bulletin=found.bulletinDb;
            self.crtOption=found.object.answerDb;
            self.registration=found.registrationDb;
          }
          self.bulletinVoted=found.voted;
          self.found=JSON.stringify(found);
          if(self.bulletin.meetQuestionDb.questType>=2) {
            self.answersArray.controls.splice(0);
            for(let i=0;i<self.bulletin.meetQuestionDb.answers.length;i++) {
              let ans=self.bulletin.meetQuestionDb.answers[i];
              self.answersArray.push(this.fb.control(''));
            }
          }
          this.notFound=false;
        } else {
          this.registration=null;
          this.notFound=true;
          this.moveFocus();
        }
      });
  }

  onSelectRadio(option) {
    this.crtOption=option;
  }

  onSaveVote() {
    let self=this;
    let result=null;
    if(self.bulletin.meetQuestionDb.questType>=2) {
      let ans=self.bulletinForm.value;
      let ttlShares=0;
      let ansObj=[];
      for(let i=0;i<ans.formArray.length;i++) {
        ttlShares += ans.formArray[i];
        let resObj={};
        resObj[self.bulletin.options[i].answerDb.o_id]=ans.formArray[i]?ans.formArray[i]:0;
        ansObj.push(resObj);
      }
      if(ttlShares>this.bulletin.shares*ans.formArray.length && !this.isBad) {
        alert(this._translate.instant("meet.votingr.register.sharesCountErrorTooMuch"));
      } else if(ttlShares<1 && !this.isBad) {
        alert(this._translate.instant("meet.votingr.register.sharesCountErrorTooFew"));
      } else {
        result=ansObj;
      }
    } else if(self.bulletin.meetQuestionDb.questType==1) {
      let resObj={};
      resObj[this.crtOption.o_id]=this.bulletin.shares;
      result=[resObj];
    }
    if(result!=null) {
      let request={
        bulletinId: this.bulletin.o_id,
        registrationId: this.registration.o_id,
        bad: this.isBad,
        result: result
      }
      this.meetsService.addVote(request, this.meetId)
        .subscribe(data=> {
          if(data.status==200) {
            this.bulletinForm.reset();
            this.registration=null;
            this.updateCrtResults();
            this.isBad=false;
          }
          this.moveFocus();
        })
    }
  }

  private updateCrtResults() {
    this.meetsService.getCurrentMeetResult(this.meetId)
      .subscribe(data=> {
        this.crtResult=data.object;
        // alert(JSON.stringify(data));
        this.moveFocus();
      })
  }

  onUpdateCurrentResult() {
    this.updateCrtResults();
  }

  moveFocus() {
    this.searchFocused=true;
    setTimeout(() => { this.searchFocused=false; })
  }

  onMakeBad() {
    this.isBad=!this.isBad;
    this.bulletinForm.controls["isBad"].setValue(this.isBad);
  }
}
