import {Injectable} from "@angular/core";
import {Http} from '@angular/http';
import {RequestMethod, RequestOptions, Request, Headers, Response} from "@angular/http";
import {Api} from "./PostRequest";
import {Company} from "../pages/companies/company.class";
import {AppService} from "./app.service";
import 'rxjs/add/operator/map';

@Injectable()
export class CompaniesService {
  private url;

  constructor(appService: AppService,
    private api: Api ) {
    this.url=appService.getUrl();
  }

  getAll() {
    let comps: Company[];
    return this.api.DoRequest(this.url+'/api/companies/list', RequestMethod.Get)
      .map((response: Response) => response.json());
  }

  saveCompany(company: Company, index: number) {
    let data=company;
    return this.api.DoRequest(this.url+'/api/companies/save', RequestMethod.Put, data)
      .map((response: Response) => response.json());
  }

  deleteCompany(company: Company) {
    let data= company;
    return this.api.DoRequest(this.url+'/api/companies/delete', RequestMethod.Delete, data)
      .map((response: Response) => response.json());
  }
}
