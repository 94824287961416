import {Component, OnInit} from "@angular/core";
import {DialogComponent, DialogService} from "ng2-bootstrap-modal";
import {FormBuilder, FormGroup} from "@angular/forms";
import {MeetsService} from "../../../../_services/meets.service";
import {Observable} from "rxjs/Observable";
import {PagerService} from "../../../../_services/pager.service";
import {ShareholderEditComponent} from "../shareholder.edit.component";

export interface ImportShareholdersModel {
  holders: {},
  meetId: number
}
@Component({
  templateUrl: './shareholder-import.component.html'
})
export class ImportShareholdersComponent extends DialogComponent<ImportShareholdersModel, string> implements ImportShareholdersModel, OnInit {
  afterEdit: boolean;
  meetId: number;
  file: File;
  importShareholersForm: FormGroup;
  holders: any[];
  crtPage: number;
  pager: any = {};
  pagedItems: any[];

  constructor(dialogService: DialogService,
              private meetsService: MeetsService,
              public fb: FormBuilder,
              private pagerService: PagerService) {
    super(dialogService);
    this.afterEdit=false;
    this.importShareholersForm = this.fb.group({
      import: []
    });
  }

  ngOnInit(): void {

  }

  setPage(page: number) {
    this.crtPage = page;
    if((page < 1 || page > this.pager.totalPages) && this.afterEdit==false) {
      return;
    }
    this.afterEdit=false;

    this.pager = this.pagerService.getPager((this.holders).length, page, 5);

    this.pagedItems=this.holders.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
      this.file = fileList[0];
    }
  }

  onUpload() {
    this.meetsService.uploadShareholdersImport(this.file, this.meetId)
      .subscribe(
        data => {
          if(data.status==200) {
            this.holders = data.object;
            this.setPage(1);
          }
        },
        error => console.log(error)
      )
  }


  fullName(holder: any) {
    if(holder.company) {
      return holder.shortName;
    } else {
      return holder.firstName + " " + holder.lastName + " " + holder.midName;
    }
  }

  onEditShareholder(holder, index) {
    this.dialogService.addDialog(ShareholderEditComponent, {
      holder: holder
    }).subscribe((res) => {
        if(res!=null) {
          this.holders[index] = res;
          this.afterEdit = true;
          this.setPage(this.crtPage);
        }
      });
  }

  submit() {
    this.meetsService.saveShareholdersImport(this.meetId, this.holders)
      .subscribe(data=> {
        if(data.status==200)
          this.close();
      });
  }
}
