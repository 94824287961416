import {Component, OnInit} from "@angular/core";
import {DialogComponent, DialogService} from "ng2-bootstrap-modal";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {MeetsService} from "../../_services/meets.service";
import {TranslateService} from "../../translate/translate.service";
import {count} from "rxjs/operator/count";
import {PagerService} from "../../_services/pager.service";

export interface SearchModel {
  meetId: any,
  onlyPeople: boolean,
  requireAttorney: boolean
  list: any[],
  findDublicate: boolean
}

@Component({
  templateUrl: "./search-holder.component.html"
})
export class SearchHolderComponent extends DialogComponent<SearchModel, string> implements SearchModel, OnInit {
  list: any[];
  findDublicate: boolean;
  requireAttorney: boolean;
  onlyPeople: boolean;
  meetId: any;
  found: any[];
  crtPage: number;
  pager: any = {};
  pagedItems: any[];
  notFound: boolean;
  result: any;
  crtMode: string;
  thisHolder: any;
  attorneyError: string;

  private searchForm: FormGroup;
  private attorneyForm: FormGroup;
  afterSearch: boolean;
  private findError: string;

  constructor(dialogService: DialogService,
              private router: Router,
              public fb: FormBuilder,
              private meetsService: MeetsService,
              private _translate: TranslateService,
              private pagerService: PagerService
  ) {
    super(dialogService);
    this.notFound=false;
    this.crtMode="find";
    this.attorneyForm=null;
    this.findError=null;
    this.findDublicate=false;
    this.searchForm=fb.group({
      searchField: ["", Validators.required]
    })
    this.attorneyForm=fb.group({
      attorneyDate: [],
      attorneyNo: [],
      attorneyValidBy: [],
      attorneyInfo: [],
      noVote: [],
      shares: ["", Validators.required]
    })
  }

  ngOnInit(): void {

  }

  search() {
    let search=this.searchForm.controls["searchField"].value;
    let found;
    let self = this;
    this.meetsService.findHolders(search, this.meetId, this.onlyPeople?2:0)
      .subscribe(data => {
        found=data.object;
        self.found=found;
        self.notFound=found.length<1;
        self.afterSearch=true;
        self.setPage(1);
      })
  }

  setPage(page: number) {
    this.crtPage = page;
    if((page < 1 || page > this.pager.totalPages) && !this.afterSearch) {
      return;
    }
    this.afterSearch=false;
    this.pager = this.pagerService.getPager(this.found.length, page, 5);
    this.pagedItems=(this.found.slice(this.pager.startIndex, this.pager.endIndex + 1));
  }

  fullName(holder) {
    if(holder.company) {
      return holder.shortName;
    } else {
      return holder.firstName+" "+
          holder.lastName+" "+
          holder.midName;
    }
  }

  secondRow(holder) {
    let addrStr="";
    if(holder.addresses!=null) {
      for (let a = 0; a < holder.addresses.length; a++) {
        let addr = holder.addresses[a];
        if (addr == 1)
          addrStr = this.ifAdd(addr.countryValue) +
            this.ifAdd(addr.regionValue) +
            this.ifAdd(addr.districtValue) +
            this.ifAdd(addr.city) +
            this.ifAdd(addr.address);
      }
    }
    if(holder.company) {
      return addrStr+" "+holder.taxId;
    } else {
      return addrStr+" "+this.ifAdd(holder.taxId)+
        this.ifAdd(this._translate.instant("docType."+holder.docType))+
        this.ifAdd(holder.docSeria)+
        this.ifAdd(holder.docNumber)+
        this.ifAdd(holder.docIssuer)+
        this.ifAdd(holder.docIssuedDate);
    }
  }

  private ifAdd(value: any) {
    if(value!=null)
      return value+" ";
    return "";
  }

  selectHolder(holder) {
    if(this.requireAttorney) {
      if(this.findDublicate && this.findDuble(holder)) {
        this.findError=this._translate.instant("meet.registration.attorney.foundDublicate");
        return;
      }
      this.thisHolder=holder;
      this.crtMode="attorney";
      this.attorneyForm.controls["shares"].setValue(holder.sharesVote);
    } else {
      this.result = {ok: true, holder: holder};
      this.close();
    }
  }

  findDuble(holder) {
    for(let i=0;i<this.list.length;i++)
      if(this.list[i].o_id===holder.o_id)
        return true;
    return false;
  }

  onApplyAttorney() {
    let attorney=this.attorneyForm.value;
    let shares=attorney.shares;
    if(shares<0 || shares>this.thisHolder.sharesVote) {
      this.attorneyError = this._translate.instant("meet.registration.attorney.sharesCountInvalid");
      return;
    }

    this.result = {ok: true, holder: this.thisHolder, attorney: attorney};
    this.close();
  }
}
