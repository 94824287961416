import {element} from "protractor";
import {Component, Input, OnInit} from "@angular/core";

@Component({
    selector: "meetBulletin",
    templateUrl: "./bulletinComponent.html"
})
export class MeetBulletinComponent implements OnInit{
  private bullData: any;
  private companyData: any;
  private registrationData: any;
  private meetDb: any;
  private question: any;
  private shares: number;
  private attorney: any;
  private attorneyList: any;

  constructor(
  ) {

  }

  ngOnInit(): void {
  }

  @Input() set isAttorney(value) {
    this.attorney = value;
  }

  @Input() set attorneyData(value) {
    this.attorneyList = value;
  }

  @Input() set data(value) {
    this.bullData=value;
    this.question=value.meetQuestionDb;
  }

  @Input() set company(value) {
    this.companyData=value;
  }

  @Input() set registration(value) {
    this.registrationData=value;
    this.shares=0;
    for (let i=0;i<value.shares.length;i++)
      this.shares+=value.shares[i].shares;
  }

  @Input() set meet(value) {
    this.meetDb=value;
  }

  forWeb(text) {
    return text.replace("\n", "<br/>");
  }

  width() {
    let ret=900/
      this.question.answers.length;
    return ret>300?ret:300;
  }

  fullName() {
    return this.registrationData.firstName+" "+
        this.registrationData.lastName+" "+
        this.registrationData.midName;
  }

  makeText(text) {
    return text.replace(/\n/g, '<br/>')
      .replace(/&/g, '&amp;')
      .replace(/>/g, '&gt;')
      .replace(/</g, '&lt;');
    ;
  }
}
