import {RouterModule, Routes} from "@angular/router";
import {StartComponent} from "./pages/start/app-start.component";
import {AuthGuard} from "./_guards/auth.guard";
import {LoginComponent} from "./login/login.component";
import {RegisterComponent} from "./register/register.component";
import {CompaniesComponent} from "./pages/companies/app-companies.component";
import {MeetsComponent} from "./pages/meets/app-meets.component";
import {MeetDetailsComponent} from "./pages/meetDetails/app-meet-details.component";
import {MeetRegistrationComponent} from "./pages/meetRegistration/meet-registration.component";
import {MeetVotingComponent} from "./pages/meetVoting/meet-voting.component";
const appRoutes: Routes = [
  { path: '', component: StartComponent, canActivate: [ AuthGuard ] },
  { path: 'companies', component: CompaniesComponent, canActivate: [ AuthGuard ] },
  { path: 'meets', component: MeetsComponent, canActivate: [ AuthGuard ] },
  { path: 'meets/:id', component: MeetDetailsComponent, canActivate: [ AuthGuard ] },
  { path: 'meets/:id/:companyId', component: MeetDetailsComponent, canActivate: [ AuthGuard ] },
  { path: 'meets/do/registration/:id', component: MeetRegistrationComponent, canActivate: [ AuthGuard ] },
  { path: 'meets/do/voting/:id', component: MeetVotingComponent, canActivate: [ AuthGuard ] },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },

  { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);
