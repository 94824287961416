import {Component, Input, OnInit} from "@angular/core";
import {MeetsService} from "../../../_services/meets.service";
import {DialogService} from "ng2-bootstrap-modal";
import {FormBuilder} from "@angular/forms";
import {TranslateService} from "../../../translate/translate.service";
import {QuestionEditComponent} from "./question-edit.component";

@Component({
  selector: 'meetQuestions',
  templateUrl: './meet-questions.component.html'
})
export class MeetQuestionsComponent implements OnInit{
  meetId: number;
  questions: any[];
  self: any;
  private isNewEditing: boolean;

  constructor(
    private meetsService: MeetsService,
    private dialogService: DialogService,
    public fb: FormBuilder,
    private _translate: TranslateService
  ) {
    this.self=this;
  }

  @Input() set meet(value) {
    this.meetId=value;
  }

  ngOnInit(): void {
    this.loadQuestions();
  }

  loadQuestions() {
    this.meetsService.loadMeetInfo('questions', this.meetId)
      .subscribe(
        data => {
          this.questions=data.object;
        }
      )
  }

  onRefreshList() {
    this.loadQuestions();
  }

  onEditQuestion(question, index) {
    this.dialogService.addDialog(QuestionEditComponent, {
      question: question
    })
      .subscribe((res) => {
        question = res;
        if(res!=null) {
          this.meetsService.saveMeetQuestion(question, this.meetId)
            .subscribe(
              data => {
                if (data.status == 200) {
                  if (index >= 0) {
                    this.questions[index] = question;
                  }
                  else {
                    this.questions.push(question);
                    this.onRefreshList();
                  }
                }
              }
            )
        }
      })
  }

  onAddQuestion() {
    this.isNewEditing=true;
    let question={ o_id:"", questionNo: "", protocolNo: "", bulletinId: "", subject: "",
      proposition: "", questType: 1, answers: [], orderId: 0, comissionType: 0, resultingAnswer: 0, resultingAnswerCondition: 0,
      cumVotedResultText: "", cumNumberOfCandidates: 0};
    this.onEditQuestion(question, -1);
  }

  onDeleteQuestion(index) {
    if(confirm(this._translate.instant("meet.questions.list.deleteConfirm"))) {
      let toDelete = this.questions[index].o_id;
      this.meetsService.deleteQuestion(toDelete)
        .subscribe(
          data => {
            if(data.status == 200) {
              this.onRefreshList();
            }
          }
        )
    }
  }

}
