import {Component, Inject} from '@angular/core';
import {Http} from '@angular/http';
import {RequestMethod, RequestOptions, Request, Headers, Response} from "@angular/http";
import {Injectable} from "@angular/core";

@Injectable()
export class Api {
  requestoptions: RequestOptions;
  private headers: Headers;

  constructor(private http: Http) { }

  DoRequest(url, reqMethod, data = {}) {
    this.headers = new Headers();
    this.headers.append("Content-Type", 'application/json');
    this.headers.append("Authorization", 'Bearer ' + localStorage.getItem('id_token'))

    this.requestoptions = new RequestOptions({
      method: reqMethod,
      url: url,
      headers: this.headers,
      body: data
    })

    return this.http.request(new Request(this.requestoptions));
  }

  DoUpload(url, formData, options) {
    let req = new RequestOptions({
      method: RequestMethod.Post,
      url: url,
      headers: options.headers,
      body: formData
    })
    return this.http.post(url, formData, options);
  }

  PostRequest(url, data) {
    return this.DoRequest(url, RequestMethod.Post, data);
  }

  GetRequest(url, data = {}) {
    return this.DoRequest(url, RequestMethod.Get, data);
  }
}
