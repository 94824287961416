import {Component, OnInit} from "@angular/core";
import {appBaseClass} from "../appBase.component";
import {CompaniesService} from "../../_services/companies.service";
import {Company} from "./company.class";
import {FormBuilder, Validators} from "@angular/forms";
import {TranslateService} from "../../translate/translate.service";

@Component({
  templateUrl: './view/companies.html'
})
export class CompaniesComponent implements OnInit{
  page;
  crtCompany;
  crtCompanyIndex;

  private companies = [];
  constructor(
    public fb: FormBuilder,
    private companiesService: CompaniesService,
    private _translate: TranslateService
  ) {
    this.page = { title: this._translate.instant("companies.page.title") };
    this.crtCompany=null;
  }

  public companyForm = this.fb.group({
    o_id: [],
    shortName: ["", Validators.required],
    fullName: [""],
    taxId: ["", Validators.required],
    factAddress: [""],
    offAddress: [""]
  });


  ngOnInit() {
    this.loadAll();
  }

  loadAll() {
    this.companiesService.getAll()
      .subscribe(
        data => {
          this.companies=data.object;
        }
      )
  }

  get self() {
    return this;
  }

  onSelectCompany(comp, index) {
    this.crtCompany=comp;
    this.companyForm.setValue(this.crtCompany);
    this.crtCompanyIndex=index;
  }

  onNewCompany() {
    this.crtCompany=new Company();
    this.companyForm.reset();
    this.crtCompanyIndex=-1;
  }

  onCloseCompany() {
    this.crtCompany=null;
  }

  onSaveCompany() {
    if(this.companyForm.valid) {
      var toSave = this.companyForm.value;
      this.companiesService.saveCompany(toSave, this.companyForm.value.o_id)
        .subscribe(
          data => {
            this.crtCompany=toSave;
            if(this.crtCompanyIndex==-1) {
              this.companies.push(this.crtCompany);
            } else {
              this.companies[this.crtCompanyIndex]=toSave;
            }
            this.onCloseCompany();
            this.loadAll();
          }
        )
    }
  }

  onResetCompanyForm() {
    this.companyForm.setValue(this.crtCompany);
  }

  onRemoveCompany(index) {
    if(confirm(this._translate.instant("companies.confirmDelete"))) {
      let toDelete = this.companies[index];
      this.companiesService.deleteCompany(toDelete)
        .subscribe(
          data => {
            this.loadAll();
          }
        )
    }
  }
}
