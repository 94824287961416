import {Pipe} from "@angular/core";

@Pipe({
  name: "sortQuestions"
})
export class SortQuestion {
  transform(array: Array<any>, args: string): Array<any> {
    array.sort((a: any, b:any) => {
      if(a.questionNo.orderId < b.questionNo.orderId) {
        return -1;
      } else if(a.questionNo.orderId > b.questionNo.orderId) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
