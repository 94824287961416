import {Inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/platform-browser";
/**
 * Created by dimm on 4/5/17.
 */


@Injectable()
export class AppService {
  private path;

  constructor(@Inject(DOCUMENT) private document: any) {
    let p=this.document.location.href.toLowerCase();
    let host="";
    let path="";
    if(p.startsWith("http://")) {
      path="http://";
    } else if(p.startsWith("https://")) {
      path="https://";
    }
    p=p.substring(path.length);
    let parts=p.split("/");
    if(parts.length>0) {
      let host=parts[0];
      if(host.startsWith("localhost")) {
        host=host.split(":")[0]+":8080";
      } else {
        host="api."+host.split(":")[0];
      }
      path+=host;
    }
    path=path+"";
    this.path=path;
  }

  getUrl() {
    return this.path;
  }
}
