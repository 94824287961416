/**
 * Created by dimm on 3/23/17.
 */
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../translate'; // our translate service

@Pipe({
  name: 'strLimit',
  pure: false // impure pipe, update value when we change language
})

export class StrLimitPipe implements PipeTransform {

  constructor(private _translate: TranslateService) { }

  transform(value: string, len: number): any {
    if (!value) return;

    if(len) {
      value = value.substr(0, len);
      if (true) {
        var lastspace = value.lastIndexOf(' ');
        if (lastspace !== -1) {
          //Also remove . and , so its gives a cleaner result.
          if (value.charAt(lastspace-1) === '.' || value.charAt(lastspace-1) === ',') {
            lastspace = lastspace - 1;
          }
          value = value.substr(0, lastspace);
        }
      }

      return value + ' …';
    }

    return value;
  }
}
