import {Component, Injectable} from "@angular/core";

export class appBaseClass {
  public page;
  public isRoot:boolean;

  constructor() {
    this.page = { title: "Default title" }
    this.isRoot = true;
  }
}
