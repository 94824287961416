import {Component, Input, OnInit} from "@angular/core";
import {MeetsService} from "../../../_services/meets.service";
import {DialogService} from "ng2-bootstrap-modal";
import {FormBuilder} from "@angular/forms";
import {TranslateService} from "../../../translate/translate.service";
import {PagerService} from "../../../_services/pager.service";
import {ShareholderEditComponent} from "./shareholder.edit.component";
import {ImportShareholdersComponent, ImportShareholdersModel} from "./import/shareholder-import.component";

@Component({
  selector: 'meetShareholders',
  templateUrl: './meet-shareholders.component.html'
})
export class MeetShareholdersComponent implements OnInit {
  afterFilter: boolean;
  isNewEditing: boolean;
  crtPage: number;
  crtShareholder: any;
  pager: any = {};
  pagedItems: any[];
  filter: "";

  allRows: any;
  filteredRows: any;
  useFilter: boolean;
  meetId: number;
  private crtIndex: number;
  private self: any;

  constructor(
    private meetsService: MeetsService,
    private dialogService: DialogService,
    public fb: FormBuilder,
    private _translate: TranslateService,
    private pagerService: PagerService
  ) {
    this.self=this;
  }

  @Input() set meet(value) {
    this.meetId=value;
  }

  ngOnInit(): void {
    this.loadAll();
    //this.setPage(1);
  }

  onRefreshList() {
    this.loadAll();
  }

  loadAll() {
    this.meetsService.loadMeetInfo('shareholders', this.meetId)
      .subscribe(
        data => {
          this.allRows=data.object;
          this.useFilter=false;
          if(this.crtPage==null)
            this.setPage(1);
        }
      )
  }

  setPage(page: number) {
    this.crtPage = page;
    if((page < 1 || page > this.pager.totalPages) && !this.afterFilter) {
      return;
    }
    this.afterFilter=false;

    this.pager = this.pagerService.getPager(((this.useFilter)?this.filteredRows:this.allRows).length, page, 5);

    this.pagedItems=((this.useFilter)?this.filteredRows:this.allRows).slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  fullName(holder: any) {
    if(holder.company) {
      return holder.shortName;
    } else {
      return holder.firstName + " " + holder.lastName + " " + holder.midName;
    }
  }

  onAddShareholder() {
    this.isNewEditing=true;
    let emptyAddress_1={ o_id: "", countryCode: "", countryValue: "", regionCode: "", regionValue: "", districtCode: "", districtValue: "",
      city: "", postIndex: "", address: "", addressType: 1};
    let emptyAddress_2={ o_id: "", countryCode: "", countryValue: "", regionCode: "", regionValue: "", districtCode: "", districtValue: "",
      city: "", postIndex: "", address: "", addressType: 2};
    let holder={ o_id:"", addresses: [ emptyAddress_1, emptyAddress_2 ], company: null, accType: 2, mfo: "", categoryType: 9, code: "",
      firstName: "", lastName: "", midName: "", docType: 3, docSeria: "", docNumber: "", docIssuer: "", docIssuedDate: "",
      taxId: "", fullName: "", shortName: "", accBank: "", accMfo: "", accNo: "", registryDate: "", registryPlace: "",
      bank: false, sharesTotal: 0, sharesVote: 0, addressesJson: [ emptyAddress_1, emptyAddress_2 ] };
    this.onEditShareholder(holder, -1);
  }

  onEditShareholder(holder, index) {
    index=(this.pager.currentPage-1) * this.pager.pageSize + index;
    this.crtIndex = index;
    this.crtShareholder = holder;
    var page=this.crtPage;
    this.dialogService.addDialog(ShareholderEditComponent, {
      holder: holder
    })
      .subscribe((res) => {
        holder = JSON.parse(res);
        if(res!=null) {
          this.meetsService.saveMeetHolder(holder, this.meetId)
            .subscribe(
              data => {
                if (data.status == 200) {
                  if (index >= 0) {
                    ((this.useFilter)?this.filteredRows:this.allRows)[index] = holder;
                    if(this.useFilter) {
                      let fltrd=this.filteredRows[index];
                      let allIndex=this.allRows.findIndex(row => {
                        if(row.o_id==fltrd.o_id)
                          return true;
                        return false;
                      });
                      this.filteredRows[index] = holder;
                      this.allRows[allIndex] = fltrd;
                    } else {
                      this.allRows[index] = holder;
                    }
                  }
                  else {
                    ((this.useFilter)?this.filteredRows:this.allRows).push(holder);
                  }
                  // this.onRefreshList();
                  this.setPage(page);
                }
              }
            )
        }
      })
  }

  onDeleteShareholder(index) {
    if(!confirm(this._translate.instant("meet.shareholders.list.deleteConfirm")))
      return;
    index=(this.pager.currentPage-1) * this.pager.pageSize + index;
    let itemToDelete=((this.useFilter)?this.filteredRows:this.allRows)[index];
    this.meetsService.deleteMeetHolder(itemToDelete)
      .subscribe(
        data=> {
          if(data.status == 200) {
            if(this.useFilter) {
              let fltrd=this.filteredRows[index];
              let allIndex=this.allRows.findIndex(row => {
                if(row.o_id==fltrd.o_id)
                  return true;
                return false;
              });
              this.filteredRows.splice(index, 1);
              this.allRows.splice(allIndex, 1);
            } else {
              this.allRows.splice(index, 1);
            }
            this.setPage(this.crtPage);
          }
        }
      )
  }

  onFilterChanged(event) {
    let filter=event.target.value;
    this.filter=filter;
    console.log(filter);
    if(filter.length>0) {
      this.useFilter=true;
      let arr = this.allRows.filter(holder => {
        let name = holder.company || holder.company == null ? holder.fullName + " " + holder.taxId :
          holder.firstName + ' ' + holder.lastName + ' ' + holder.midName + " " + holder.taxId;
        let res = name.toLowerCase().indexOf(filter.toLowerCase()) !== -1;
        return res;
      });
      this.filteredRows = arr;
      this.afterFilter=true;
      this.setPage(1);
    } else {
      this.useFilter=false;
      this.afterFilter=true;
      this.setPage(1);
    }
  }

  onImportShareholders() {
    this.dialogService.addDialog(ImportShareholdersComponent, {
      meetId: this.meetId
    })
      .subscribe((res) => {
        this.loadAll();
      })
  }
}
