/**
 * Created by dimm on 3/23/17.
 */
export const LANG_RU_NAME = 'ru';

export const LANG_RU_TRANS = {
  'hello world': 'Привет',
  "mainPage": "Главная",
  "loading": "Загрузка",
  "save": "Сохранить",
  "start.title": "Начало",
  "back": "Назад",
  "delete": "Удалить",
  "first": "В начало",
  "previous": "Назад",
  "next": "Вперед",
  "last": "В конец",
  "close": "Закрыть",                               // ==================================

  'leftSide.dashboard': 'Начало',
  "leftSide.meetsSub": "Собрания акционеров",
  "leftSide.companies": "Компании",
  "leftSide.meets": "Собрания",

  'login.page.title': "Система авторизации",
  "login.title": "Авторизация",
  "login.enterUsername": "Введите имя пользователя",
  "login.username": "Имя пользователя",
  "login.usernameRequired": "Имя пользователья должно быть указано",
  "login.enterPassword": "Введите пароль",
  "login.password": "Пароль",
  "login.passwordRequired": "Пароль должен быть указан",
  "login.enter": "Войти",

  "companies.form.add": "Добавить",

  "companies.page.title": "Справочник предприятий",
  "companies.listTitle": "Список акционерных обществ",
  "companies.list.header.name": "Наименование",
  "companies.list.header.taxId": "Код ЕГРПОУ",
  "companies.list.header.actions": "Действия",
  "companies.form.shortName": "Краткое наименование",
  "companies.form.fullName": "Полное наименование",
  "companies.form.taxId": "Код ЕГРПОУ",
  "companies.form.factAddress": "Фактический адрес",
  "companies.form.offAddress": "Юридический адрес",
  "companies.confirmDelete": "Удалить предприятие?",

  "meets.page.title": "Собрания",

  "meets.companiesList.title": "Предприятия",
  "meets.meetsList.title": "Собрания",
  "meets.meetsList.header.date": "Дата",
  "meets.meetsList.header.place": "Место проведения",
  "meets.meetsList.header.actions": "Действия",

  "meet.general.title": "Общая информация",
  "meet.general.form.meetDate": "Дата проведения собрания",
  "meet.general.form.meetPlace": "Место проведения собрания",
  "meet.general.form.regStartAt": "Время начала регистрации",
  "meet.general.form.regEndAt": "Время окончания регистрации",
  "meet.general.form.meetStartAt": "Время начала проведения собрания",
  "meet.general.form.registryDate": "Дата реестра акционеров",

"meetedit.page.title": "Редактирование данных о собрании",

  "meet.comissions.title": "Состав комиссий",
  "meet.comissions.header.fullName": "Ф.И.О.",
  "meet.comissions.header.rank": "Должность",
  "meet.comissions.header.conission": "Комиссия",
  "meet.comissions.header.actions": "Действия",
  "meet.comissions.header.isHead": "Глава",

  "comission.1": "Регистрационная",
  "comission.2": "Счетная",
  "comission.3": "Глава",
  "comission.4": "Секретарь",
  "comission.5": "Временная счетная комиссия",

  "meet.comission.member.form.title": "Редактирование данных о члене комиссии",
  "meet.comission.member.form.fullName": "Ф.И.О.",
  "meet.comission.member.form.rank": "Должность",
  "meet.comission.member.form.comission": "Член комиссии",
  "meet.comission.member.form.isHead": "Глава",
  "meet.comission.member.confirmDelete": "Удалить члена комиссии?",

  "meet.questions.title": "Повестка дня",
  "meet.questions.header.question_no": "Вопрос",
  "meet.questions.header.bulletin_no": "Бюллетень",
  "meet.questions.header.type": "Тип",
  "meet.questions.header.subject": "Тема",
  "meet.questions.header.actions": "Действия",

  "bulletin.1": "Бюллетенями",
  "bulletin.2": "Кумулятивное",
  "bulletin.3": "Комбинированный",

  "meet.question.form.title": "Редактирование вопроса повестки дня",
  "meet.question.form.questionNo": "Номер вопроса",
  "meet.question.form.bulletinId": "Номер бюллетеня",
  "meet.question.form.subject": "Тема вопроса",
  "meet.question.form.proposition": "Предложение",
  "meet.question.form.questType": "Тип голосования",

  "meet.questions.answers.header.answer_id": "Номер",
  "meet.questions.answers.header.title": "Заголовок",
  "meet.questions.form.selectGeneralInfo": "Основная информация",
  "meet.questions.form.selectAnswersList": "Варианты",
  "meet.questions.answerNr": "Вариант №",

  "meet.question.form.answer.questionNo": "Номер ответа",
  "meet.question.form.answer.title":      "Текст ответа",
  "meet.question.form.answer.cumFullName": "Ф.И.О. кандидата",
  "meet.question.form.answer.cumRank": "Должность кандидата",
  "meet.question.form.answer.cumInfo": "Дополнительная информация о кандидате",
  "meet.questions.list.editQuestion": "Редактировать",
  "meet.questions.list.deleteQuestion": "Удалить",
  "meet.questions.list.deleteConfirm": "Удалить пункт повестки дня?",
  "meet.questions.header.protocol_no": "Протокол",

  "meet.shareholders.header.code": "Код",
  "meet.shareholders.header.name": "Наименование / Ф.И.О.",
  "meet.shareholders.header.sharesVote": "Голосующих акций",
  "meet.shareholders.header.actions": "Действия",
  "meet.shareholders.title": "Список акционеров",

  "meet.shareholder.form.title": "Редактирование данных об акционере",
  "meet.shareholder.form.general": "Общая информация",
  "meet.shareholder.form.addresses": "Адреса",
  "meet.shareholder.form.shares": "Акции",
  "meet.shareholder.form.code": "Код",
  "meet.shareholder.form.category": "Категория",
  "meet.shareholder.form.accType": "Тип счета",

  "categoryType.1": "Новые",                // Нові
  "categoryType.2": "Разница по акциям",    // Різниця по акціях
  "categoryType.3": "Представитель банка",  // Представник банку
  "categoryType.4": "Член правления",       // Член правління
  "categoryType.5": "Управляющий",          // Керуючий
  "categoryType.6": "Совет директоров",     // Рада директорів
  "categoryType.7": "Ревизионная комиссия", // Ревізійна комісія
  "categoryType.8": "Большой акционер",     // Великий акціонер
  "categoryType.9": "Другие",               // Інше
  "categoryType.10": "Отказано",            // Відмова

  "accType.1": "Эмитент",                   // Емітент
  "accType.2": "Собственник",               // Власник
  "accType.3": "Номинальный держатель",     // Номінальний утримувач
  "accType.4": "Залогодержатель",           // Заставоутримувач
  "accType.5": "Уполномоченное лицо",       // Уповноважена особа

  "docType.0": "Паспорт",
  "docType.1": "Военный билет",
  "docType.2": "Справка из ВВС",
  "docType.3": "Паспорт",
  "docType.4": "Удостоверение личности",
  "docType.5": "Свидетельство о рождении",
  "docType.6": "Свидетельство о смерти",
  "docType.7": "ID-карта",

  "meet.shareholder.form.firstName": "Фамилия",
  "meet.shareholder.form.lastName": "Имя",
  "meet.shareholder.form.midName": "Отчество",
  "meet.shareholder.form.docType": "Тип документа",
  "meet.shareholder.form.docSeria": "Серия",
  "meet.shareholder.form.docNumber": "Номер",
  "meet.shareholder.form.docIssuedDate": "Дата выдачи",
  "meet.shareholder.form.docIssuer": "Орган, выдавший документ",
  "meet.shareholder.form.taxId": "Идентификационный номер",
  "meet.shareholder.form.taxIdCompanies": "Код ЕГРПОУ",
  "meet.shareholder.form.registryDate": "Дата регистрации",
  "meet.shareholder.form.registryPlace": "Орган регистрации",
  "meet.shareholder.form.shortName": "Краткое наименование",
  "meet.shareholder.form.fullName": "Полное наименование",
  "meet.shareholder.form.accMfo": "МФО",
  "meet.shareholder.form.accBank": "Банк",
  "meet.shareholder.form.accNo": "Номер счета",
  "meet.shareholder.form.address.countryCode": "Код страны",
  "meet.shareholder.form.address.countryValue": "Страна",
  "meet.shareholder.form.address.regionCode": "Код региона",
  "meet.shareholder.form.address.regionValue": "Регион",
  "meet.shareholder.form.address.districtCode": "Код района",
  "meet.shareholder.form.address.districtValue": "Район",
  "meet.shareholder.form.address.city": "Населенный пункт",
  "meet.shareholder.form.address.postIndex": "Индекс",
  "meet.shareholder.form.address.address": "Адрес",
  "meet.shareholder.form.address.sharesTotal": "Количество акций всего",
  "meet.shareholder.form.address.sharesVote": "Из них голосующих",
  "meet.shareholder.form.isCompany": "Принадлежность",

  "companyType.1": "Юридическое лицо",
  "companyType.2": "Физическое лицо",

  "addressType.1": "Юридический",
  "addressType.2": "Фактический",

  "search": "Поиск",
  "find": "Найти",

  "meet.shareholders.list.deleteConfirm": "Удалить акционера?",

  "meets.meetsList.header.addActions": "Собрание",
  "meets.meetsList.header.actions.registration": "Регистрация",
  "meets.meetsList.header.actions.voting": "Голосование",
  "meets.meetsList.header.actions.result": "Результаты",

  "meet.registration.title": "Регистрация акционеров",
  "meet.registration.shareholders.title": "Зарегистрированные акционеры",
  "meet.registration.action.title": "Данные об участнике",
  "meet.registration.shareholderFind.form.findField": "Поиск акционера",
  "meet.registration.listIsEmpty": "Пока никто не зарегистрирован",
  "meets.registration.findHolder.header.name": "Наименование",
  "meets.registration.findHolder.header.shares": "Количество голосов",
  "meets.registration.findHolder.header.actions": "Действия",

  "meet.registration.voter.firstName": "Фамилия",
  "meet.registration.voter.lastName": "Имя",
  "meet.registration.voter.midName": "Отчество",
  "meet.registration.voter.docSeria": "Серия д-та",
  "meet.registration.voter.docNumber": "Номер д-та",
  "meet.registration.voter.docDate": "Дата выдачи",
  "meet.registration.voter.docIssuer": "Место выдачи документа",
  "meet.registration.voter.selectButton": "Выбрать",
  "meet.searchHolder.title": "Поиск в реестре акционеров",
  "meet.searchHolder.searchField": "Поисковый запрос",
  "meet.searchHolder.notFound": "Ничего не найдено",
  "meet.registration.voter.taxId": "Идентификационный номер",
  "meet.registration.voter.shares": "Собственных акций",
  "meet.registration.voteslist.title": "Делегированные голоса",
  "meet.registration.delegate.own": "Собственные",
  "meet.registration.voteslist.add": "Добавить доверенность",
  "meets.registration.findHolder.header.code": "Код",
  "meet.registration.voter.code": "Код акционера",
  "meet.registration.voter.attorneyFrom": "Доверенность от",
  "meet.registration.voter.attorneyDate": "Дата",
  "meet.registration.voter.attorneyNo": "Номер",
  "meet.registration.voter.attorneyValidBy": "Срок действия",
  "meet.registration.voter.attorneyInfo": "Дополнительная информация",
  "meet.registration.voter.attorneyShares": "Количество акций",
  "meet.registration.voter.attorneySharesAvailable": "Доступно акций",
  "meet.registration.attorney.sharesCountInvalid": "Ошибочное количество акций",
  "meet.registration.voteslist.sharesTotal": "Выдано голосов",
  "meet.registration.attorney.foundDublicate": "Найден дубль",
  "meet.registration.register": "Зарегистрировать",
  "meet.registration.cancelEdit": "Закрыть",
  "meet.registration.cancelRegistration": "Отменить регистрацию",
  "meet.registration.printBulletin": "Печать бюллетеней",
  "meet.registration.cancelConfirm": "Отменить регистрацию участника? Выданные бюллетени будут аннулированы, а назначенные голоса будут возвращены акционеру как неиспользованные и станут доступны для повторной регистрации",
  "meet.printbulletin.title": "Печать бюллетеней",
  "meet.printbulletin.print": "Печать",
  "meet.registration.SharesCountMoreThanInOwn": "Указанный акционер уже делегировал все свои акции: ",
  "meet.registration.registrationInfoTitle": "Текущая информация",
  "meet.registration.info.participantsCount": "Зарегистрировано участников",
  "meet.registration.info.sharesCount": "Зарегистрировано акций",
  "meet.registration.info.sharesTotal": "Всего акций у предприятия",
  "meet.registration.info.sharesTotalShares": "Всего акций у предприятия",
  "meet.registration.info.sharesTotalToVote": "Из них голосующих",

  "meet.voting.title": "Процедура голосования",
  "meet.voting.resultsTable.title": "Текущие результаты",
  "meet.voting.registerTable.title": "Регистрация голосов",
  "meet.voting.searchField": "QR-код",
  "meet.voting.searchTitle": "Поиск по коду",
  "meet.voting.registration.participant": "Участник",
  "meet.voting.registration.shares": "Количество акций",
  "meet.voting.registration.question": "Вопрос",
  "meet.voting.bullType.1": "Бюллетень простого типа",
  "meet.voting.bullType.2": "Бюллетень кумулятивного логосования",
  "meet.voting.bullType.3": "Комбинированный бюллетень",

  "meet.voting.bulletenType": "Тип голосования по вопросу",
  "meet.voting.variants": "Варианты голосования",
  "meet.votingr.register.sharesCountErrorTooMuch": "Ошибка назначения голосов: общее количество голосов по бюллетеню больше, чем в наличии у акционера",
  "meet.votingr.register.sharesCountErrorTooFew": "Не указано количество голосов по бюллетеню",
  "meet.voting.qrCode.notFound": "Ничего не найдено",
  "meet.voting.registration.searchCode": "Поисковый запрос",
  "warning": "Внимание!",
  "meet.voting.bulletinVoted.part": "Этот бюллетень уже проголосовал. При сохранении новых результатов, старые будут удалены!",
  "meet.voting.resultsTable.result": "Результат",
  "meet.voting.resultsTable.question": "Вопрос",
  "meet.voting.resultsTable.count": "Всего голосов",

  "meet.general.form.decisionNo": "Номер решения о проведении собрания",
  "meet.general.form.decisionDate": "Дата решения",
  "meet.general.form.meetType": "Тип собрания",
  "meet.question.form.comissionType": "Подписывается комиссией",

  "meetType.1": "Загальні збори акціонерів",
  "meetType.2": "Позачергові загальні збори акціонерів",

  "meetType.of.1": "загальних зборів акціонерів",
  "meetType.of.2": "позачергових загальних зборів акціонерів",
  "meetType.ofat.1": "загальних зборах акціонерів",
  "meetType.ofat.2": "позачергових загальних зборах акціонерів",
  "meetType.by.1": "загальними зборами акціонерів",
  "meetType.by.2": "позачерговими загальними зборами акціонерів",

  "meet.reports.title": "Печать отчетов по собранию",
  "meet.reports.print": "Печатать",
  "meet.reports.generate": "Сгенерировать",

  "text.hasQuorum.true": "мають кворум і мають право розпочати",
  "text.hasQuorum.false": "не мають кворум і не мають право розпочати",
  "meet.question.form.resultingAnswer": "Ответ, определяющий принятие решения",
  "meet.question.form.resultingAnswerCondition": "Количество голосов, необходимых для принятия решения (%)",
  "meet.question.form.resultingAnswerCondition.1" : "Простое большинство",
  "meet.question.form.resultingAnswerCondition.2" : "Три четверти голосов",
  "meet.question.form.resultingAnswerCondition.3" : "50% от общего числа акций",
  "meet.question.form.protocolNo": "Н-р протокола",
  "meet.question.form.cumVotedResultText": "Текст в результатах голосования",
  "meet.question.form.cumNumberOfCandidates": "Выбираемое количество кандидатов",

  "text.decision.1": "прийнято",
  "text.decision.2": "не прийнято",
  "text.decision.true": "прийнято",
  "text.decision.false": "не прийнято",

  "meet.shareholders.import.title": "Импорт списка акционеров",
  "meet.shareholders.import.importHere": "Импортируемый текст",

  "upload": "Загрузить",
  "meet.reports.selectAll": "Выбрать все",
  "text.comissionTypeOf.1": "Реєстраційної комісії",
  "text.comissionTypeOf.2": "Лічильної комісії",
  "text.comissionType.1": "Реєстраційна комісія",
  "text.comissionType.2": "Лічильна комісія",
  "text.comissionTypeOf.5": "Тимчасової лічильної комісії",
  "text.comissionType.5": "Тимчасова лічильна комісія",
  "meet.voting.bulletinIsBad": "Бюллетень недействителен",
  "meet.general.form.certificateNo": "Номер сертификата",
  "meet.general.form.certificateDate": "Дата",
  "meet.general.form.certificateBy": "Орган выдачи",
  "meet.general.form.nomValue": "Номинальная стоимость",
  "meet.general.form.sharesCount": "Общее количество акций",
  "meet.general.form.bullSignRank": "Должность подписанта бюллетеней",
  "meet.general.form.bullSignFullname": "Ф.И.О. подписанта бюллетеней",

  "text.headOfComission.1": "Голова реєстраційної комісії",
  "text.headOfComission.2": "Голова лічильної комісії",
  "text.headOfComission.5": "Голова тимчасової лічильної комісії",
  "text.headOfComission.6": "Голова або член реєстраційної комісії",

};
