import {Component, OnInit} from '@angular/core';
import {TranslateService} from "./translate/translate.service";

@Component({
  moduleId: module.id,
  selector: 'app-root',
  templateUrl: './pageParts/page.html'
})

export class AppComponent implements OnInit {
  translatedText: string;
  supportedLangs: any[];
  name:string;
  constructor(
    private _translate: TranslateService
  ) {
    this.name='Angular2';
  }

  ngOnInit(): void {
    this.supportedLangs = [
      { display: 'Ukrainian', value: 'uk' },
      { display: 'Russian', value: 'ru' }
    ]

    this.selectLang('ru');
  }

  isCurrentLang(lang: String) {
    return lang === this._translate.currentLang;
  }

  selectLang(lang: string) {
    this._translate.use(lang);
    this.refreshText();
  }

  refreshText() {
    this.translatedText = this._translate.instant('hello world');
  }
}
