import {Component, OnInit} from "@angular/core";
import {DialogComponent, DialogService} from "ng2-bootstrap-modal";
import {MeetsService} from "../../../_services/meets.service";
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
/**
 * Created by dimm on 3/30/17.
 */

export interface ShareholderModel {
  holder: {},
  readOnly: boolean;
}

@Component({
  templateUrl: './shareholder.edit.component.html'
})
export class ShareholderEditComponent extends DialogComponent<ShareholderModel, string> implements ShareholderModel, OnInit {
  crtAddressIndex: number;
  holder: { company: boolean, addresses: any[], addressesJson: any[] };
  crtTab: {};
  crtAddress: any;
  readOnly: boolean;
  private holderEditForm: FormGroup;
  private addressEditForm: FormGroup;
  private companyEditForm: FormGroup;

  constructor(dialogService: DialogService,
              private meetsService: MeetsService,
              private router: Router,
              public fb: FormBuilder) {
    super(dialogService);
    this.crtTab="general";
    this.holderEditForm = this.fb.group({
      o_id: [], company: [], accType: ["", Validators.required], mfo: [], categoryType: ["", Validators.required], code: ["", Validators.required],
      firstName: [], lastName: [], midName: [], docType: [], docSeria: [], docNumber: [], docIssuer: [], docIssuedDate: [],
      taxId: [], fullName: [], shortName: [], accBank: [], accMfo: [], accNo: [], registryDate: [],
      registryPlace: [], bank: [], sharesTotal: ["", Validators.required], sharesVote: ["", Validators.required], addresses: [], addressesJson: []
    });
    this.addressEditForm = this.fb.group({
      o_id: [], countryCode: [""], countryValue: [""],
      regionCode: [], regionValue: [""],
      districtCode: [], districtValue: [],
      city: [""], postIndex: [""], address: [""], addressType: []
    });
    this.companyEditForm = this.fb.group({
      company: ["", Validators.required]
    })
  }

  ngOnInit(): void {
    if(this.holder.addresses==null) {
      this.loadAddresses();
    } else {
      this.holderEditForm.setValue(this.holder);
      this.initAddresses();
    }
  }

  loadAddresses() {
    let self=this;
    this.meetsService.loadHolderAddresses(self.holder)
      .subscribe(data=> {
        if(data.status==200) {
          self.holder.addresses=data.object;
          self.holderEditForm.setValue(self.holder);
          self.initAddresses();
        }
      })
  }

  initAddresses() {
    if(this.holder.addresses.length>0) {
      this.crtAddress=this.holder.addresses[0];
      this.crtAddressIndex=0;
      this.addressEditForm.setValue(this.crtAddress);
    }
  }

  makeEqualNames() {
    let name = this.holderEditForm.value.shortName;
    this.holderEditForm.controls["fullName"].setValue(name);
  }

  selectTab(tab) {
    this.crtTab=tab;
  }

  onSelectAddress(address, index) {
    this.crtAddress=address;
    this.crtAddressIndex=index;
    this.addressEditForm.setValue(this.crtAddress);
  }

  onSaveAddress() {
    let index=this.crtAddressIndex;
    if(!this.addressEditForm.valid)
      return;
    let toSave = this.addressEditForm.value;
    this.holder.addresses[index]=toSave;
    this.crtAddress=this.holder.addresses[index];
  }

  apply() {
    if(this.holderEditForm.valid) {
      this.holder = this.holderEditForm.value;
      this.holder.addressesJson=
        this.holder.addresses;
      this.result=JSON.stringify(this.holder);
      this.close();
    }
  }

  applyCompany() {
    if(!this.companyEditForm.valid)
      return;
    let val=this.companyEditForm.value;
    this.holder.company=val.company==1;
    this.holderEditForm.setValue(this.holder);
  }

  onCloneAddress() {
    this.crtAddress=JSON.parse(JSON.stringify(this.holder.addresses[0]));
    this.crtAddress.addressType=2;
    this.addressEditForm.setValue(this.crtAddress);
  }
}
