import {Component, OnInit} from "@angular/core";
import {DialogComponent, DialogService} from "ng2-bootstrap-modal";
import {MeetsService} from "../../_services/meets.service";
/**
 * Created by dimm on 4/7/17.
 */

export interface PrintBulletinModel {
  meetId: number,
  registrationId: number
}

@Component({
  templateUrl: "./meet-registration-bulletins.component.html"
})
export class MeetRegistrationPrintBulletinComponent extends DialogComponent<PrintBulletinModel, string> implements PrintBulletinModel, OnInit {
  registrationId: number;
  meetId: number;
  private data: any;
  private meetDb: any;
  private companyDb: any;
  private registrationDb: any;
  private bulletinList: any;
  private attorneyDataList: any;
  private isAttorney: any;

  constructor(dialogService: DialogService,
    private meetsService: MeetsService) {
    super(dialogService);
  }

  ngOnInit(): void {
    this.loadBulletinsData();
  }

  printToCart(printSectionId: string) {
    let popupWinindow
    let innerContents = document.getElementById(printSectionId).innerHTML;
    popupWinindow = window.open('', '_blank', 'width=900,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    popupWinindow.document.write('<html><head>  <link rel="stylesheet" href="/assets/css/bootstrap.min.css"><link rel="stylesheet" id="css-main" href="/assets/css/oneui.css"></head><body onload="window.print()">' + innerContents + '</html>');
    popupWinindow.document.close();
  }

  private loadBulletinsData() {
    let load={
      meetId: this.meetId,
      registrationId: this.registrationId
    }
    let self=this;
    this.meetsService.loadBulletins(load)
      .subscribe(resp => {
        let row=resp.object;
        self.data=row;
        self.meetDb=row.meetDb;
        self.companyDb=row.meetDb.company;
        self.registrationDb=row.registrationDb;
        self.bulletinList=row.bulletinDbList;
        self.isAttorney = row.isAttorney;
        self.attorneyDataList = row.bulletinAttorneyData;
      })
  }

  fullName(share) {
    if(share.holder.company) {
      return share.holder.shortName;
    } else {
      return share.holder.firstName+" "+
        share.holder.lastName+" "+
        share.holder.midName;
    }
  }

  secondCol(share) {
    if(share.holder.company) {
      return share.holder.taxId;
    } else {
      return share.holder.docSeria!=null?share.holder.docSeria:" "+" "+
        share.holder.docNumber;
    }
  }

  sharesOwn(share) {
    if(share.holder.code===this.registrationDb.code)
      return share.shares;
    else
      return 0;
  }
  sharesAttorney(share) {
    if(share.holder.code===this.registrationDb.code)
      return 0
    else
      return share.shares;
  }
  sharesTotal(share) {
    return share.shares;
  }
  totalShares() {
    let ret=0;
    for(let i=0;i<this.registrationDb.shares.length;i++)
      ret+=this.registrationDb.shares[i].shares;
    return ret;
  }
}
