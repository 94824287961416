import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "../../translate/translate.service";

@Component({
  templateUrl: './view/meetDetails.html'
})
export class MeetDetailsComponent implements OnInit {
  crtCompany: any;
  id: any; page: { title: string; };
  private sub: any;
  isNew: boolean;

  constructor(private route: ActivatedRoute, private _translate: TranslateService) {
    this.page = { title: this._translate.instant("meetedit.page.title") }
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      let id=params['id'];
      if(id>=0) {
        this.id = id;
        this.isNew=false;
      } else {
        let cId=params["companyId"];
        if(cId!=null) {
          this.id = -1;
          this.isNew = true;
          this.crtCompany=cId;
        }
      }
    });
  }
  get self()  {
    return this;
  }
}
