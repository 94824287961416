import {Component} from "@angular/core";
import {TranslateService} from "../../translate/translate.service";

@Component({
  templateUrl: './view/start.html'
})

export class StartComponent {
  page:string;
  constructor(private _translate: TranslateService) {
    this.page=this._translate.instant("start.title");
  }
}
