/**
 * Created by dimm on 3/23/17.
 */
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../translate'; // our translate service

@Pipe({
  name: 'companyType',
  pure: false
})

export class CompanyTypePipe implements PipeTransform {

  constructor(private _translate: TranslateService) { }

  transform(value: string, args: any[]): any {
    if (!value) return;

    return this._translate.instant("companyType."+value);
  }
}
