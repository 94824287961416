import {Injectable} from "@angular/core";
import {Http, RequestOptions, Response, Headers} from "@angular/http";
import {Api} from "./PostRequest";
import {AppService} from "./app.service";
@Injectable()
export class AuthenticationService {
  private url;

  constructor(
    appService: AppService,
    private http: Http,
    private api: Api) {
    this.url=appService.getUrl();
  }

  login(username: string, password: string) {
    var queryUser = JSON.stringify({ username: username, password: password });

    return this.api.PostRequest(this.url+'/api/users/authenticate', queryUser)
      .map((response: Response) => {
        let user = response.json();
        if(user && user.token) {
          localStorage.setItem('currentUser', JSON.stringify(user));
        }
      });

    // return this.http.post('http://localhost:8080/api/users/authenticate', queryUser,  options )
    //   .map((response: Response) => {
    //
    //     let user = response.json();
    //     if (user && user.token) {
    //       localStorage.setItem('currentUser', JSON.stringify(user));
    //     }
    //   });
  }

  logout() {
    localStorage.removeItem('currentUser');
  }
}
