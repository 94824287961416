import {Component, OnInit} from "@angular/core";
import {DialogComponent, DialogService} from "ng2-bootstrap-modal";
import {MeetsService} from "../../_services/meets.service";

export interface PrintReportsModel {
  meet: any
}

@Component({
  templateUrl: "./meet-reports.component.html"
})
export class MeetPrintReportsComponent extends DialogComponent<PrintReportsModel, string> implements PrintReportsModel, OnInit {
  reportsData: any[];
  meet: any;
  reports: any[];
  json: any;
  report: any;

  constructor(dialogService: DialogService,
              private meetsService: MeetsService) {
    super(dialogService);
  }

  ngOnInit(): void {
    this.fillReportsList();
  }

  private fillReportsList() {
    this.meetsService.getReportsList(this.meet.o_id)
      .subscribe(data=> {
        this.json=data;
        if(data.status==200) {
          this.reports = data.object;
          for(let i=0;i<this.reports.length;i++)
            this.reports[i].checked=false;
        }
      })
  }

  private reportChanged(checked, repIndex) {
    this.reports[repIndex].checked=checked;
  }

  public makeReport() {
    let repIds=[];
    for(let i=0;i<this.reports.length;i++) {
      if(this.reports[i].checked)
        repIds.push(this.reports[i].code)
    }
    this.meetsService.getReport(this.meet.o_id, repIds)
      .subscribe(data => {
        this.reportsData=data.object;
        this.json=data;
      });
  }

  printToCart(printSectionId: string) {
    let popupWinindow
    let innerContents = document.getElementById(printSectionId).innerHTML;
    popupWinindow = window.open('', '_blank', 'width=900,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    popupWinindow.document.open();
    popupWinindow.document.write('<html><head>  <link rel="stylesheet" href="/assets/css/bootstrap.min.css"><link rel="stylesheet" id="css-main" href="/assets/css/oneui.css"></head><body onload="window.print()">' + innerContents + '</html>');
    popupWinindow.document.close();
  }

  selectAll() {
    for(let i=0;i<this.reports.length;i++)
      this.reports[i].checked=true;
  }
}
