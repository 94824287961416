/**
 * Created by dimm on 3/23/17.
 */
export const LANG_UK_NAME = 'uk';

export const LANG_UK_TRANS = {
  'hello world': 'Привіт',
  "mainPage": "Головна",
  "loading": "Завантаження",
  "save": "Зберегти",
  "start.title": "Початок",
  "back": "Назад",
  "delete": "Видалити",
  "first": "На початок",
  "previous": "Назад",
  "next": "Вперед",
  "last": "Вкінець",
  "close": "Закрити",


  'leftSide.dashboard': 'Початок',
  "leftSide.meetsSub": "Збори акціонерів",
  "leftSide.companies": "Компанії",
  "leftSide.meets": "Збори",

  'login.page.title': "Система авторизації",
  "login.title": "Авторизація",
  "login.enterUsername": "Увести ім’я користувача",
  "login.username": "Ім’я користувача",
  "login.usernameRequired": "Треба указати ім’я користувача",
  "login.enterPassword": "Введіть пароль",
  "login.password": "Пароль",
  "login.passwordRequired": "Треба указати пароль",
  "login.enter": "Увійти",

  "companies.form.add": "Додати",

  "companies.page.title": "Довідник підприємств",
  "companies.listTitle": "Список акціонерних товариств",
  "companies.list.header.name": "Найменування",
  "companies.list.header.taxId": "Код ЄДРПОУ",
  "companies.list.header.actions": "Дії",
  "companies.form.shortName": "Коротке найменування",
  "companies.form.fullName": "Повне найменування",
  "companies.form.taxId": "Код ЄДРПОУ",
  "companies.form.factAddress": "Фактична адреса",
  "companies.form.offAddress": "Юридична адреса",
  "companies.confirmDelete": "Видалити підприємство?",

  "meets.page.title": "Збори",

  "meets.companiesList.title": "Підприємство",
  "meets.meetsList.title": "Збори",
  "meets.meetsList.header.date": "Дата",
  "meets.meetsList.header.place": "Місце проведення",
  "meets.meetsList.header.actions": "Дії",

  "meet.general.title": "Загальна інформація",
  "meet.general.form.meetDate": "Дата проведення зборів",
  "meet.general.form.meetPlace": "Місце проведення зборів",
  "meet.general.form.regStartAt": "Година початку реєстрації",
  "meet.general.form.regEndAt": "Година закінчення реєстрації",
  "meet.general.form.meetStartAt": "Година початку проведення зборів",

  "meetedit.page.title": "Редагувати дані про збори",

  "meet.comissions.title": "Склад комісії",
  "meet.comissions.header.fullName": "П.І.Б.",
  "meet.comissions.header.rank": "Посада",
  "meet.comissions.header.conission": "Комісія",
  "meet.comissions.header.actions": "Дії",
  "meet.comissions.header.isHead": "Голова",

  "comission.1": "Реєстраційна",
  "comission.2": "Лічильна",
  "comission.3": "Голова",
  "comission.4": "Секретар",
  "comission.5": "Тимчасова лічильна комісія",

  "meet.comission.member.form.title": "Редагувати дані про члена комісії",
  "meet.comission.member.form.fullName": "П.І.Б.",
  "meet.comission.member.form.rank": "Посада",
  "meet.comission.member.form.comission": "Член комісії",
  "meet.comission.member.form.isHead": "Голова",
  "meet.comission.member.confirmDelete": "Видалити члена комісії?",

  "meet.questions.title": "Порядок денний",
  "meet.questions.header.question_no": "Питання",
  "meet.questions.header.bulletin_no": "Бюлетень",
  "meet.questions.header.type": "Тип",
  "meet.questions.header.subject": "Тема",
  "meet.questions.header.actions": "Дії",

  "bulletin.1": "Бюлетенями",
  "bulletin.2": "Кумулятивне",

  "meet.question.form.title": "Редагувати питання порядку денного",
  "meet.question.form.questionNo": "Номер питання",
  "meet.question.form.bulletinId": "Номер бюлетеня",
  "meet.question.form.subject": "Тема питання",
  "meet.question.form.proposition": "Пропозиція",
  "meet.question.form.questType": "Тип голосування",

  "meet.questions.answers.header.answer_id": "Номер",
  "meet.questions.answers.header.title": "Заголовок",
  "meet.questions.form.selectGeneralInfo": "Основна інформація",
  "meet.questions.form.selectAnswersList": "Варіанти",
  "meet.questions.answerNr": "Варіант №",

  "meet.question.form.answer.questionNo": "Номер відповіді",
  "meet.question.form.answer.title": "Текст відповіді",
  "meet.question.form.answer.cumFullName": "П.І.Б. кандидата",
  "meet.question.form.answer.cumRank": "Посада кандидата",
  "meet.question.form.answer.cumInfo": "Додаткова інформація про кандидата",
  "meet.questions.list.editQuestion": "Редагувати",
  "meet.questions.list.deleteQuestion": "Видалити",
  "meet.questions.list.deleteConfirm": "Видалити пункт з порядку денного?",

  "meet.shareholders.header.code": "Код",
  "meet.shareholders.header.name": "Найменування / П.І.Б.",
  "meet.shareholders.header.sharesVote": "Акції, що голосують",
  "meet.shareholders.header.actions": "Дії",
  "meet.shareholders.title": "Список акціонерів",

  "meet.shareholder.form.title": "Редагувати дані про акціонерів",
  "meet.shareholder.form.general": "Загальна інформація",
  "meet.shareholder.form.addresses": "Адреса",
  "meet.shareholder.form.shares": "Акції",
  "meet.shareholder.form.code": "Код",
  "meet.shareholder.form.category": "Категорія",
  "meet.shareholder.form.accType": "Тип рахунку",

  "categoryType.1": "Нові",
  "categoryType.2": "Різниця по акціям",    "categoryType.3": "Представник банку",   "categoryType.4": "Член правління",       "categoryType.5": "Керуючий",          "categoryType.6": "Рада директорів",     "categoryType.7": "Ревізійна комісія", "categoryType.8": "Великий акціонер",     "categoryType.9": "Інше",               "categoryType.10": "Відмова",
  "accType.1": "Емітент",

  "accType.2": "Власник",
  "accType.3": "Номінальний утримувач",     "accType.4": "Заставоутримувач",           "accType.5": "Уповноважена особа",
  "docType.0": "Паспорт",
  "docType.1": "Військовий квиток",
  "docType.2": "Довідка із ВВС",
  "docType.3": "Паспорт",
  "docType.4": "Посвідчення особи",
  "docType.5": "Свідоцтво про народження",
  "docType.6": "Свідоцтво про смерть",
  "docType.7": "ID-карта",

  "meet.shareholder.form.firstName": "Прізвище",
  "meet.shareholder.form.lastName": "Ім’я",
  "meet.shareholder.form.midName": "По батькові",
  "meet.shareholder.form.docType": "Тип документа",
  "meet.shareholder.form.docSeria": "Серія",
  "meet.shareholder.form.docNumber": "Номер",
  "meet.shareholder.form.docIssuedDate": "Дата видачі",
  "meet.shareholder.form.docIssuer": "Орган, що видав документ",
  "meet.shareholder.form.taxId": "Ідентифікаційний номер",
  "meet.shareholder.form.taxIdCompanies": "Код ЄДРПОУ",
  "meet.shareholder.form.registryDate": "Дата реєстрації",
  "meet.shareholder.form.registryPlace": "Орган реєстрації",
  "meet.shareholder.form.shortName": "Коротке найменування",
  "meet.shareholder.form.fullName": "Повне найменування",
  "meet.shareholder.form.accMfo": "МФО",
  "meet.shareholder.form.accBank": "Банк",
  "meet.shareholder.form.accNo": "Номер рахунку",
  "meet.shareholder.form.address.countryCode": "Код країни",
  "meet.shareholder.form.address.countryValue": "Країна",
  "meet.shareholder.form.address.regionCode": "Код регіону",
  "meet.shareholder.form.address.regionValue": "Регіон",
  "meet.shareholder.form.address.districtCode": "Код району",
  "meet.shareholder.form.address.districtValue": "Район",
  "meet.shareholder.form.address.city": "Населений пункт",
  "meet.shareholder.form.address.postIndex": "Індекс",
  "meet.shareholder.form.address.address": "Адрес",
  "meet.shareholder.form.address.sharesTotal": "Загальна кількість акцій",
  "meet.shareholder.form.address.sharesVote": "Із них, що голосують",
  "meet.shareholder.form.isCompany": "Належність",

  "companyType.1": "Юридична особа",
  "companyType.2": "Фізична особа",

  "addressType.1": "Юридичний",
  "addressType.2": "Фактичний",

  "search": "Пошук",
  "find": "Знайти",

  "meet.shareholders.list.deleteConfirm": "Видалити акціонера?",

  "meets.meetsList.header.addActions": "Збори",
  "meets.meetsList.header.actions.registration": "Реєстрація",
  "meets.meetsList.header.actions.voting": "Голосування",
  "meets.meetsList.header.actions.result": "Результати",

  "meet.registration.title": "Реєстрація акціонерів",
  "meet.registration.shareholders.title": "Зареєстровані акціонери",
  "meet.registration.action.title": "Дані про учасників",
  "meet.registration.shareholderFind.form.findField": "Пошук акціонерів",
  "meet.registration.listIsEmpty": "Поки що ніхто не зареєструвався",
  "meets.registration.findHolder.header.name": "Найменування",
  "meets.registration.findHolder.header.shares": "Кількість голосів",
  "meets.registration.findHolder.header.actions": "Дії",

  "meet.registration.voter.firstName": "Прізвище",
  "meet.registration.voter.lastName": "Ім’я",
  "meet.registration.voter.midName": "По батькові",
  "meet.registration.voter.docSeria": "Серія документа",
  "meet.registration.voter.docNumber": "Номер документа",
  "meet.registration.voter.docDate": "Дата видачі",
  "meet.registration.voter.docIssuer": "Місце видачі документа",
  "meet.registration.voter.selectButton": "Вибрати",
  "meet.searchHolder.title": "Пошук в реєстрі акціонерів",
  "meet.searchHolder.searchField": "Пошуковий запит",
  "meet.searchHolder.notFound": "Нічого не знайдено",
  "meet.registration.voter.taxId": "Ідентифікаційний номер",
  "meet.registration.voter.shares": "Власних акцій",
  "meet.registration.voteslist.title": "Делеговані голоси",
  "meet.registration.delegate.own": "Власні",
  "meet.registration.voteslist.add": "Добавити довіреність",
  "meets.registration.findHolder.header.code": "Код",
  "meet.registration.voter.code": "Код акціонера",
  "meet.registration.voter.attorneyFrom": "Довіреність від",
  "meet.registration.voter.attorneyDate": "Дата",
  "meet.registration.voter.attorneyNo": "Номер",
  "meet.registration.voter.attorneyValidBy": "Термін дії",
  "meet.registration.voter.attorneyInfo": "Додаткова інформація",
  "meet.registration.voter.attorneyShares": "Кількість акцій",
  "meet.registration.voter.attorneySharesAvailable": "Доступно акцій",
  "meet.registration.attorney.sharesCountInvalid": "Помилкова кількість акцій",
  "meet.registration.voteslist.sharesTotal": "Видано голосів",
  "meet.registration.attorney.foundDublicate": "Знайдено дубль",
  "meet.registration.register": "Зареєструвати",
  "meet.registration.cancelEdit": "Закрити",
  "meet.registration.cancelRegistration": "Відмінити реєстрацію",
  "meet.registration.printBulletin": "Друк бюлетенів",
  "meet.registration.cancelConfirm": "Відмінити реєстрацію учасника? Видані бюлетені буде анульовано, а назначені голоси повернуто акціонеру як не використані й будуть доступні для повторної реєстрації",
  "meet.printbulletin.title": "Друк бюлетенів",
  "meet.printbulletin.print": "Друк",
  "meet.registration.SharesCountMoreThanInOwn": "Зазначений акціонер вже делегував всі свої акції:",
  "meet.registration.registrationInfoTitle": "Поточна інформація",
  "meet.registration.info.participantsCount": "Зареєстровано учасників",
  "meet.registration.info.sharesCount": "Зареєстровано акцій",
  "meet.registration.info.sharesTotal": "Всього акцій у підприємства",

  "meet.voting.title": "Процедура голосування",
  "meet.voting.resultsTable.title": "Поточні результати",
  "meet.voting.registerTable.title": "Реєстрація голосів",
  "meet.voting.searchField": "QR-код",
  "meet.voting.searchTitle": "Пошук за кодом",
  "meet.voting.registration.participant": "Учасник",
  "meet.voting.registration.shares": "Кількість акцій",
  "meet.voting.registration.question": "Питання",
  "meet.voting.bullType.1": "Бюлетень простого типу",
  "meet.voting.bullType.2": "Бюлетень кумулятивного голосування",
  "meet.voting.bulletenType": "Тип голосування за питанням",
  "meet.voting.variants": "Варіанти голосування",
  "meet.votingr.register.sharesCountErrorTooMuch": "Помилка призначення голосів: загальна кількість голосів за бюлетенем більша, ніж є в наявності в акціонера",
  "meet.votingr.register.sharesCountErrorTooFew": "Не вказано кількість голосів за бюлетенем",
  "meet.voting.qrCode.notFound": "Нічого не знайдено",
  "meet.voting.registration.searchCode": "Пошуковий запит",
  "warning": "Увага!",
  "meet.voting.bulletinVoted.part": " Даний бюлетень вже проголосував. При збережені нових результатів – старі буде видалено!",
  "meet.voting.resultsTable.result": "Результат",
  "meet.voting.resultsTable.question": "Питання",
  "meet.voting.resultsTable.count": "Всього голосів",



  "meetType.1": "Загальні збори акціонерів",
  "meetType.2": "Позачергові загальні збори акціонерів",
};
