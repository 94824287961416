/**
 * Created by dimm on 3/23/17.
 */
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../translate'; // our translate service

@Pipe({
  name: 'textPipe',
  pure: false
})

export class TextPipe implements PipeTransform {

  constructor(private _translate: TranslateService) { }

  transform(value: string, args: any[]): any {
    if (!value) return;
    let prefix="";
    if(args!=null && args.length>0)
      prefix=args+".";

    return this._translate.instant("text."+prefix+value);
  }
}
