import {Component, OnInit} from "@angular/core";
import {TranslateService} from "../../translate/translate.service";
import {ActivatedRoute} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {MeetsService} from "../../_services/meets.service";
import {ShareholderEditComponent} from "../meetDetails/meetShareholders/shareholder.edit.component";
import {DialogService} from "ng2-bootstrap-modal";
import {SearchHolderComponent} from "./search-holder.component";
import {MeetRegistrationPrintBulletinComponent} from "./meet-registration-bulletins.component";
import {PagerService} from "../../_services/pager.service";

@Component({
  templateUrl: './meet-registration.component.html'
})
export class MeetRegistrationComponent implements OnInit{
  sub: Subscription;
  private page: { title: string };
  private meetId: number;
  private registered: any[];
  private participantForm : FormGroup;
  private findList: any[];
  private holders: any[];
  private thisHolder: any;
  public fromDb: boolean;
  private crtReg: any;
  private registrationError: string;
  private isSaving: boolean;
  private crtPage: number;
  pagedItems: any[];
  pager: any = {};
  private registrationInfo: any;
  private afterEdit: boolean;

  constructor(
    private route: ActivatedRoute, private _translate: TranslateService,
    public fb: FormBuilder, private meetsService: MeetsService,
    private dialogService: DialogService, private pagerService: PagerService
  ) {
    this.holders=[];
    this.page = { title: this._translate.instant("meet.registration.title") }
    this.participantForm = this.fb.group({
      sharesVote: [], firstName: ["", Validators.required], lastName: ["", Validators.required],
      midName: ["", Validators.required], docSeria: ["", Validators.required], docNumber: ["", Validators.required],
      docDate: ["", Validators.required], docIssuer: [], taxId: ["", Validators.required],
      company: [], code: [], shortName: [], holder_id: [], attorney: []
    });
    this.findList=[];
    this.fromDb=false;
    this.isSaving=false;
  }

  setPage(page: number) {
    this.crtPage = page;
    if((page < 1 || page > this.pager.totalPages) && !this.afterEdit) {
      return;
    }
    this.afterEdit=false;
    this.pager = this.pagerService.getPager(this.registered.length, page, 5);
    this.pagedItems=(this.registered.slice(this.pager.startIndex, this.pager.endIndex + 1));
  }

  ngOnInit(): void {
    this.registered=[];
    this.sub = this.route.params.subscribe(params => {
      this.meetId = params['id'];
    });
    this.loadData();
  }

  get self()  {
    return this;
  }

  onViewHolder(holder) {
    this.dialogService.addDialog(ShareholderEditComponent, {
      holder: holder,
      readOnly: true
    })
      .subscribe();
  }

  onSearchShareholder() {
    if(this.fromDb)
      return;
    let self=this;
    this.dialogService.addDialog(SearchHolderComponent, {
      meetId: this.meetId,
      requireAttorney: false,
      onlyPeople: true,
      list: this.holders,
      findDublicate: true
    })
      .subscribe((ret)=>{
      if(ret!=null && ret.ok==true) {
          let data: any;
          data = ret.holder;
          let holder=this.fromData(data);
          self.participantForm.setValue(holder);
          self.thisHolder=holder;
          self.disableForm(true);
          self.addHolder(holder);
        }
      });
  }

  private fromData(data: any) {
    let holder = {
        holder_id: data.o_id,
        firstName: data.firstName,
        lastName: data.lastName,
        midName: data.midName,
        docSeria: data.docSeria,
        docNumber: data.docNumber,
        docDate: data.docIssuedDate,
        docIssuer: data.docIssuer,
        taxId: data.taxId,
        sharesVote: data.sharesVote,
        company: data.company,
        code: data.code,
        shortName: data.shortName,
        attorney: data.attorney!=null?data.attorney:{}
    }
    return holder;
  }

  onAddHolder() {
    if(this.fromDb)
      return;
    let self=this;
    this.dialogService.addDialog(SearchHolderComponent, {
      meetId: this.meetId,
      onlyPeople: false,
      requireAttorney: true,
      list: this.holders,
      findDublicate: true
    })
      .subscribe((ret)=>{
        if(ret!=null && ret.ok==true) {
          let data: any;
          data = ret.holder;
          let attorney=ret.attorney;
          data.attorney=attorney;
          let holder = this.fromData(data);
          holder.sharesVote=ret.attorney.shares;
          self.addHolder(holder);
        }
      });
  }

  private disableForm(b: boolean) {
    if(b) {
      this.participantForm.controls["firstName"].disable();
      this.participantForm.controls["lastName"].disable();
      this.participantForm.controls["docSeria"].disable();
      this.participantForm.controls["docNumber"].disable();
      this.participantForm.controls["docDate"].disable();
      this.participantForm.controls["docIssuer"].disable();
      this.participantForm.controls["taxId"].disable();
      this.participantForm.controls["midName"].disable();
      this.participantForm.controls["code"].disable();
    } else {
      this.participantForm.controls["firstName"].enable();
      this.participantForm.controls["lastName"].enable();
      this.participantForm.controls["docSeria"].enable();
      this.participantForm.controls["docNumber"].enable();
      this.participantForm.controls["docDate"].enable();
      this.participantForm.controls["docIssuer"].enable();
      this.participantForm.controls["taxId"].enable();
      this.participantForm.controls["midName"].enable();
      this.participantForm.controls["code"].enable();
    }
  }

  fullName(row) {
    let holder=row;
    if(row.participant!=null)
      holder=row.participant;
    if(holder.company) {
      return holder.shortName;
    } else {
      return holder.firstName+" "+
        holder.lastName+" "+
        holder.midName;
    }
  }

  secondRow(holder) {
    let addrStr="";
    if(holder.addresses!=null) {
      for (let a = 0; a < holder.addresses.length; a++) {
        let addr = holder.addresses[a];
        if (addr == 1)
          addrStr = this.ifAdd(addr.countryValue) +
            this.ifAdd(addr.regionValue) +
            this.ifAdd(addr.districtValue) +
            this.ifAdd(addr.city) +
            this.ifAdd(addr.address);
      }
    }
    if(holder.company) {
      return addrStr+" "+holder.taxId;
    } else {
      return addrStr+" "+this.ifAdd(holder.taxId)+
        this.ifAdd(this._translate.instant("docType."+holder.docType))+
        this.ifAdd(holder.docSeria)+
        this.ifAdd(holder.docNumber)+
        this.ifAdd(holder.docIssuer)+
        this.ifAdd(holder.docIssuedDate);
    }
  }

  private ifAdd(value: any) {
    if(value!=null)
      return value+" ";
    return "";
  }

  private addHolder(holder: any) {
    this.holders.push(holder);
  }

  private votesTotal() {
    let cnt=0;
    for(let i=0;i<this.holders.length;i++) {
      cnt+=this.holders[i].sharesVote;
    }
    return cnt;
  }


  onDeleteHolder(index) {
    let part=this.holders[index];
    if(part!=null) {
      this.holders.splice(index,1);
    }
  }

  onRefreshRegistrationList() {
    this.loadData();
  }

  onRegisterParticipant() {
    if(this.thisHolder==null) {
      this.thisHolder=this.participantForm.value;
    }
    let save={
      participant: this.thisHolder,
      holders: this.holders,
      meetId: this.meetId,
      o_id: 0
    }
    this.isSaving=true;
    let self=this;
    this.meetsService.registerParticipant(save, this.meetId)
      .subscribe(data=> {
        if(data.status==200) {
          save.o_id=data.object;
          self.registered.push(save);
          self.onSelectRegistration(save);
          this.registrationError=null;
        } else {
          if(data.message==="SharesCountMoreThanInOwn") {
            this.registrationError=this._translate.instant("meet.registration.SharesCountMoreThanInOwn")+
              this.fullName(data.object);
          }
        }
        this.afterEdit=true;
        this.setPage(this.crtPage);
        this.isSaving=false;
        this.updateInfo();
      })
  }

  private loadData() {
    this.meetsService.loadRegistration(this.meetId)
      .subscribe(data => {
        this.registered=data.object;
        this.afterEdit=true;
        this.setPage(1);
        this.updateInfo();
      })
  }

  onSelectRegistration(reg) {
    this.crtReg=reg;
    this.thisHolder=reg.participant;
    this.holders=reg.holders;
    this.participantForm.setValue(reg.participant);
    this.fromDb=true;
    this.disableForm(true)
  }

  onCancelEdit() {
    this.thisHolder=null;
    this.holders=[];
    this.participantForm.reset();
    this.fromDb=false;
    this.disableForm(false);
  }

  onCancelRegistration() {
    if(confirm(this._translate.instant("meet.registration.cancelConfirm"))) {
      this.meetsService.cancelRegistration(this.crtReg, this.meetId)
        .subscribe(data => {
          if(data.status==200) {
            let ind=this.registered.indexOf(this.crtReg);
            this.registered.splice(ind, 1);
            this.onCancelEdit();
            this.afterEdit=true;
            this.setPage(this.crtPage)
            this.updateInfo();
          }
        });
    }
  }

  onPrintBulletins() {
    this.dialogService.addDialog(MeetRegistrationPrintBulletinComponent, {
      meetId: this.meetId,
      registrationId: this.crtReg.o_id
    })
      .subscribe((ret)=>{

      });
  }

  private updateInfo() {
    this.meetsService.getRegistrationInfo(this.meetId)
      .subscribe(data => {
        if(data.status==200) {
          this.registrationInfo = data.object;
          let perc=Math.round((this.registrationInfo.sharesCount/
            this.registrationInfo.companyShares)*100);
          this.registrationInfo.percent=perc;
          if(perc>50) {
            this.registrationInfo.percentColor="progress-bar-success";
          } else if(perc>30) {
            this.registrationInfo.percentColor="progress-bar-warning";
          } else {
            this.registrationInfo.percentColor="progress-bar-danger";
          }
        }
      })
  }
}
