import {Component, Input} from "@angular/core";
import {MeetsService} from "../../../_services/meets.service";
import {FormBuilder, FormGroup, Validators, AbstractControl} from "@angular/forms";
import {Router} from "@angular/router";
import {AlertService} from "../../../_services/alert.service";

@Component({
  selector: 'meetGeneralInfo',
  templateUrl: './meet-general.component.html'
})
export class MeetGeneralInfoComponent {
  new: any;
  private meetId;
  generalInfoForm: FormGroup;
  private formData; doSave; companyId;

  @Input() set meet(value) {
    this.meetId=value;
  }

  @Input() set isNew(value){
    this.new=value;
  }

  @Input() set company(value) {
    this.companyId=value;
  }

  constructor(
    private meetsService: MeetsService,
    public fb: FormBuilder,
    private router: Router,
    private alertService: AlertService
  ) {
    this.generalInfoForm = this.fb.group({
      o_id: [], companyId: [],
      meetDate: ["", Validators.required],
      meetPlace: ["", Validators.required],
      meetStartAt: ["", Validators.required],
      regStartAt: ["", Validators.required],
      regEndAt: ["", Validators.required],
      company: [],
      meetType: [],
      decisionDate: [],
      decisionNo: [],
      registryDate: [],
      certificateNo: [],
      certificateDate: [],
      certificateBy: [],
      nomValue: [],
      sharesCount: [], bullSignRank: [], bullSignFullname: []
    })
  }

  ngOnInit(): void {
    if(!this.new) {
      this.loadMeetInfo();
    } else {
      this.formData={ o_id:"", meetDate: "", meetPlace: "", meetStartAt: "", regStartAt: "", regEndAt: "", companyId: this.companyId,
        decisionNo: "", decisionDate: "", meetType: 1, company: {}, registryDate: "", certificateNo: "", certificateDate: "",
        certificateBy: "", nomValue: 0, sharesCount: 0, bullSignRank: "", bullSignFullname: ""};
      this.fillForm();
    }
  }

  loadMeetInfo() {
    this.meetsService.loadMeetInfo('general', this.meetId)
      .subscribe(
        data => {
          this.formData=data.object;
          this.fillForm();
        }
      )
  }

  private fillForm() {
    this.generalInfoForm.setValue(this.formData);
  }

  onSaveGeneralInfo(andClose: boolean) {
    this.doSave=true;

    if(this.generalInfoForm.valid) {
      let dataToSave=this.generalInfoForm.value;
      if(this.new) {
        dataToSave.companyId=this.companyId;
      }
      this.meetsService.saveGeneralInfo(dataToSave, this.meetId)
        .subscribe(
          data => {
            this.doSave = false;
            if (andClose)
              this.onBackToList();
          }
        )
    }
  }

  onBackToList() {
    this.router.navigate(['meets'])
  }
}
