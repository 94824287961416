import { InjectionToken } from '@angular/core';

import { LANG_RU_NAME, LANG_RU_TRANS } from './lang-ru';
import { LANG_UK_NAME, LANG_UK_TRANS } from './lang-uk';

export const TRANSLATIONS = new InjectionToken('translations');

export const dictionary = {
  "ru": LANG_RU_TRANS,
  "uk": LANG_UK_TRANS
};

export const TRANSLATION_PROVIDERS = [
  { provide: TRANSLATIONS, useValue: dictionary }
];
