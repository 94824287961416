/**
 * Created by dimm on 3/21/17.
 */
export class Company {
  o_id: number;
  shortName: string;
  fullName: string;
  taxId: string;
  factAddress: string;
  offAddress: string;
}
