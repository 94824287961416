import {Component, OnInit} from "@angular/core";
import {appBaseClass} from "../appBase.component";
import {CompaniesService} from "../../_services/companies.service";
import {MeetsService} from "../../_services/meets.service";
import {Router} from "@angular/router";
import {TranslateService} from "../../translate/translate.service";
import {MeetPrintReportsComponent} from "../reports/meet-reports.component";
import {DialogService} from "ng2-bootstrap-modal";

@Component({
  templateUrl: './view/meets.html'
})

export class MeetsComponent implements OnInit {
  page: { title: string; };
  companies; meets; crtCompany;

  constructor(
    private companiesService: CompaniesService,
    private meetsService: MeetsService,
    private router: Router, private _translate: TranslateService,
    private dialogService: DialogService
  ) {
    this.page = { title: this._translate.instant("meets.page.title") }
  }

  ngOnInit(): void {
    this.loadAllCompanies();
  }

  loadAllCompanies() {
    this.companiesService.getAll()
      .subscribe(
        data => {
          this.companies=data.object;
          if(!this.crtCompany && this.companies.length>0) {
            this.crtCompany = this.companies[0];
            this.loadMeets();
          }
        }
      )
  }

  get self()  {
    return this;
  }

  onReloadCompaniesList() {
    this.loadAllCompanies();
  }

  onSelectCompany(comp) {
    if(this.crtCompany!=comp) {
      this.crtCompany = comp;
      this.loadMeets();
    }
  }
  loadMeets() {
    this.meets=null;
    this.meetsService.getMeetsByCompany(this.crtCompany)
      .subscribe(
        data => {
          this.meets = data.object;
        }
      )
  }

  onSelectMeet(meet, index) {
    this.router.navigate(['meets', meet.o_id])
  }

  onAddMeet() {
    this.router.navigate(['meets', "new", this.crtCompany.o_id])
  }

  onRegistrationClick(meet) {
    this.router.navigate(['meets', 'do', 'registration', meet.o_id])
  }

  onMeetVoting(meet) {
    this.router.navigate(['meets', 'do', 'voting', meet.o_id])
  }

  onCleanMeen(meet, i) {
    if(confirm('Очистить данные о собрании: \n- Данные регистрации\n- Данные голосования')) {
      this.meetsService.cleanMeet(meet.o_id)
        .subscribe(
          data => {

          });
    }
  }

  onPrintReports(meet, i) {
    this.dialogService.addDialog(MeetPrintReportsComponent, {
      meet: meet
    })
      .subscribe((ret)=>{

      });
  }
}
