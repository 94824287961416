import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import localeRu from '@angular/common/locales/ru-UA';
import localeRuExtra from '@angular/common/locales/extra/ru-UA';
import {FormBuilder, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpModule, RequestMethod} from '@angular/http';
import { AppComponent } from './app.component';
import { SidebarComponent } from "./pageParts/part.sidebar";
import {StartComponent} from "./pages/start/app-start.component";
import {CompaniesComponent} from "./pages/companies/app-companies.component";
import {MeetsComponent} from "./pages/meets/app-meets.component";
import {ContentHeaderElmnt} from "./pageParts/contentHeader/contentHerader.component";
import {AlertComponent} from "./_directives/alert.component";
import {AlertService} from "./_services/alert.service";
import {AuthGuard} from "./_guards/auth.guard";
import {AuthenticationService} from "./_services/authentication.service";
import {UserService} from "./_services/user.service";
import {routing} from "./app.routing";
import {LoginComponent} from "./login/login.component";
import {RegisterComponent} from "./register/register.component";
import {Api} from "./_services/PostRequest";
import {CompaniesService} from "./_services/companies.service";
import {Company} from "./pages/companies/company.class";
import {MeetsService} from "./_services/meets.service";
import {MeetDetailsComponent} from "./pages/meetDetails/app-meet-details.component";
import {MeetGeneralInfoComponent} from "./pages/meetDetails/meetGeneralInfo/meet-general.component";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MeetComissionsComponent} from "./pages/meetDetails/meetComission/meet-comission.component";
import {TRANSLATION_PROVIDERS, TranslatePipe, TranslateService}   from './translate';
import {ComissionPipe} from "./_directives/comission.pipe";
import {BootstrapModalModule} from 'ng2-bootstrap-modal';
import {MeetComissionMemberForm} from "./pages/meetDetails/meetComission/member-edit.conponent";
import {MeetQuestionsComponent} from "./pages/meetDetails/meetQuestions/meet-questions.component";
import {BulletinPipe} from "./_directives/bulletin.pipe";
import {StrLimitPipe} from "./_directives/str.limit.pipe";
import {QuestionEditComponent} from "./pages/meetDetails/meetQuestions/question-edit.component";
import {MeetShareholdersComponent} from "./pages/meetDetails/meetShareholders/meet-shareholders.component";
import {PagerService} from "./_services/pager.service";
import {ShareholderEditComponent} from "./pages/meetDetails/meetShareholders/shareholder.edit.component";
import {AccTypePipe} from "./_directives/accType.pipe";
import {DocTypePipe} from "./_directives/docType.pipe";
import {CategoryTypePipe} from "./_directives/categoryType.pipe";
import {AddressTypePipe} from "./_directives/addressType.pipe";
import {CompanyTypePipe} from "./_directives/companyType.pipe";
import {MeetRegistrationComponent} from "./pages/meetRegistration/meet-registration.component";
import {AppService} from "./_services/app.service";
import {SearchHolderComponent} from "./pages/meetRegistration/search-holder.component";
import {MeetRegistrationPrintBulletinComponent} from "./pages/meetRegistration/meet-registration-bulletins.component";
import {MeetBulletinComponent} from "./pages/meetRegistration/bulletins/bulletinComponent";
import {QrCode} from "./pageParts/qrCode/qrCode.component";
import {MeetVotingComponent} from "./pages/meetVoting/meet-voting.component";
import {FocusDirective} from "./_directives/focusInput";
import {MeetTypePipe} from "./_directives/meetType.pipe";
import {MeetPrintReportsComponent} from "./pages/reports/meet-reports.component";
import {MeetReportCanvas} from "./pages/reports/canvas/meetReport-canvas.component";
import {MeetTypeOfPipe} from "./_directives/meetTypeOf.pipe";
import {TextPipe} from "./_directives/text.pipe";
import {SortRegistrationReport} from "./_directives/sortRegistrationReport.pipe";
import {
  ImportShareholdersComponent
} from "./pages/meetDetails/meetShareholders/import/shareholder-import.component";
import {SortQuestion} from "./sortQuestionsPipe";
import {NewlinePipe} from "./_directives/newLine.pipe";
import {registerLocaleData} from "@angular/common";

registerLocaleData(localeRu, 'ru-RU', localeRuExtra);

@NgModule({
  declarations: [
    AppComponent, SidebarComponent, StartComponent, CompaniesComponent, MeetsComponent, ContentHeaderElmnt,
    AlertComponent, LoginComponent, RegisterComponent, MeetDetailsComponent, MeetGeneralInfoComponent, MeetComissionsComponent,
    TranslatePipe, ComissionPipe, MeetComissionMemberForm, MeetQuestionsComponent, BulletinPipe, StrLimitPipe, QuestionEditComponent,
    MeetShareholdersComponent, ShareholderEditComponent, AccTypePipe, DocTypePipe, CategoryTypePipe, AddressTypePipe, CompanyTypePipe,
    MeetRegistrationComponent, SearchHolderComponent, MeetRegistrationPrintBulletinComponent, MeetBulletinComponent, QrCode,
    MeetVotingComponent, FocusDirective, MeetTypePipe, MeetPrintReportsComponent, MeetReportCanvas, MeetTypeOfPipe, TextPipe,
    SortRegistrationReport, ImportShareholdersComponent, SortQuestion, NewlinePipe

  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    ReactiveFormsModule,
    routing, NgbModule.forRoot(), BootstrapModalModule
  ],
  providers: [
    AlertService,
    AuthGuard,
    AuthenticationService,
    Api,
    CompaniesService, MeetsService, PagerService,
    UserService, Company, TranslateService, TRANSLATION_PROVIDERS, AppService, { provide: LOCALE_ID, useValue: "ru-RU" }
  ],
  bootstrap: [AppComponent],
  entryComponents: [  MeetComissionMemberForm, QuestionEditComponent, ShareholderEditComponent, SearchHolderComponent,
    MeetRegistrationPrintBulletinComponent, MeetPrintReportsComponent, ImportShareholdersComponent, SidebarComponent ]
})
export class AppModule {
}
