import {Component, OnInit} from "@angular/core";
import {DialogComponent, DialogService} from "ng2-bootstrap-modal";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

export interface MemberModel {
  member: {}
}

@Component({
  selector: 'prompt',
  templateUrl: './member-edit.component.html'
})
export class MeetComissionMemberForm extends DialogComponent<MemberModel, string> implements MemberModel, OnInit {
  member: {};
  meetMemberForm: FormGroup;

  constructor(dialogService: DialogService,
              public fb: FormBuilder) {
    super(dialogService);
    this.meetMemberForm = this.fb.group({
      o_id: [], meet_id: [],
      fullName: ["", Validators.required],
      rank: ["", Validators.required],
      comission: ["", Validators.required],
      head: [false]
    })
  }

  ngOnInit(): void {
    this.meetMemberForm.setValue(this.member);
  }

  apply() {
    if(this.meetMemberForm.valid) {
      this.result=this.meetMemberForm.value;
      this.close();
    }
  }
}
