import {Component, Input, OnInit} from "@angular/core";
import {AppService} from "../../_services/app.service";

@Component({
  selector: "qrcode",
  templateUrl: './qrCode.component.html'
})
export class QrCode implements OnInit{
  private qrSize: number;
  private qrValue: string;
  private src: string;
  private url: string;

  ngOnInit(): void {
    this.updateSrc();
  }

  updateSrc() {
    this.src=this.url+"/api/qrcode?size="+this.qrSize+"&value="+this.qrValue;
  }

  constructor(appService: AppService) {
    this.src=null;
    this.url=appService.getUrl();
  }

  @Input() set size(value) {
    this.qrSize=value;
    this.updateSrc();
  }

  @Input() set value(value) {
    this.qrValue=value;
    this.updateSrc();
  }
}
