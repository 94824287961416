import {Component, Input, OnInit} from "@angular/core";
import {DialogComponent, DialogService} from "ng2-bootstrap-modal";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MeetsService} from "../../../_services/meets.service";
import {Router} from "@angular/router";

export interface QuestionModel {
  question: {}
}

@Component({
  selector: '',
  templateUrl: './question-edit.component.html'
})
export class QuestionEditComponent extends DialogComponent<QuestionModel, string> implements QuestionModel, OnInit{
  isNewAnswer: boolean;
  crtAnswerIndex: number;
  meetId: any;
  question: { answers: any[] };
  answers: any[];
  crtTab = "general";
  resultingAnswer: number;
  private questionEditForm: FormGroup;
  private answerEditForm: FormGroup;
  private crtAnswer: any;

  constructor(dialogService: DialogService,
              meetsService: MeetsService,
              private router: Router,
              public fb: FormBuilder) {
    super(dialogService);
    this.questionEditForm = this.fb.group({
      o_id: [], orderId: [], answers: [],
      questionNo: ["", Validators.required],
      bulletinId: ["", Validators.required],
      subject: ["", Validators.required],
      proposition: ["", Validators.required],
      questType: ["", Validators.required],
      comissionType: ["", Validators.required],
      resultingAnswer: [],
      resultingAnswerCondition: [],
      protocolNo: [],
      cumVotedResultText: [],
      cumNumberOfCandidates: []
    });
    this.answerEditForm=this.fb.group({
      o_id: [],
      answer_id: ["", Validators.required], title: ["", Validators.required], cumRank: [], cumFullname: [], cumInfo: []
    })
  }

  @Input() set meet(value) {
    this.meetId=value;
  }

  ngOnInit(): void {
    console.log(JSON.stringify(this.question));
    this.questionEditForm.setValue(this.question);
    this.answers=this.question.answers;
    if(this.answers.length>0)
      this.onSelectAnswer(this.answers[0], 0);
  }

  apply() {
    if(this.questionEditForm.valid) {
      this.result=this.questionEditForm.value;
      this.close();
    }
  }

  applyAnswer() {
    if(this.answerEditForm.value) {
      if(this.isNewAnswer) {
        this.answers.push(this.answerEditForm.value);
        this.onSelectAnswer(this.answers[this.answers.length-1], this.answers.length-1);
      } else {
        this.answers[this.crtAnswerIndex] = this.answerEditForm.value;
        this.crtAnswer=this.answerEditForm.value;
        // this.onSelectAnswer(this.crtAnswer, this.crtAnswerIndex);
      }
      this.isNewAnswer=false;
    }
  }

  selectTab(tabName) {
    this.crtTab=tabName;
  }

  onSelectAnswer(answer, index) {
    this.crtAnswerIndex=index;
    this.crtAnswer=answer;
    this.answerEditForm.setValue(answer);
    this.isNewAnswer=false;
  }

  onNewAnswer() {
    this.isNewAnswer=true;
    this.crtAnswer={ o_id: "", answer_id: "", title: "", cumFullname: "", cumRank: "", cumInfo: ""};
    this.answerEditForm.setValue(this.crtAnswer);
  }

  onDeleteAnswer() {
    if(this.crtAnswerIndex>=0) {
      this.answers.splice(this.crtAnswerIndex, 1);
      if(this.answers.length>0)
        this.onSelectAnswer(this.answers[0], 0);
      else {
        this.onNewAnswer();
      }
    }
  }
}
