import {Component, Input} from "@angular/core";
import {appBaseClass} from "../../pages/appBase.component";

@Component({
  selector: "contentHeader",
  templateUrl: './view.html'
})
export class ContentHeaderElmnt {
  private _parent: any;
  page = { title: "[default]" }

  @Input() set parent(value) {
    this._parent = value;
    this.page=value.page;
  }

  get parent(): appBaseClass {
    return this._parent;
  }
}
