import {Component, Input, OnInit} from "@angular/core";
import {MeetsService} from "../../../_services/meets.service";
import {FormBuilder} from "@angular/forms";
import {DialogService} from "ng2-bootstrap-modal";
import {MeetComissionMemberForm} from "./member-edit.conponent";
import {TranslateService} from "../../../translate/translate.service";

@Component({
  selector: "meetComissions",
  templateUrl: './meet-comission.component.html'
})
export class MeetComissionsComponent implements OnInit {
  members: any;
  meetId: number;
  crtMemberId: number;
  constructor(
    private meetsService: MeetsService,
    private dialogService: DialogService,
    public fb: FormBuilder,
    private _translate: TranslateService
  ) {

  }
  ngOnInit(): void {
    this.loadComissionsMembers();
  }

  @Input() set meet(value) {
    this.meetId=value;
  }

  private loadComissionsMembers() {
    this.meetsService.loadMeetInfo('comissions', this.meetId)
      .subscribe(
        data => {
          this.members=data.object;
        }
      )
  }

  onRefreshList() {
    this.loadComissionsMembers();
  }

  onEditMember(member: any, index: number) {
    this.crtMemberId = member.o_id;
    this.openEdit(member, index);
  }

  onAddMember() {
    this.crtMemberId = -1;
    this.openEdit({
      fullName: "", rank: "", comission: 0, head: false, o_id: -1, meet_id: this.meetId
    }, -1);
  }

  openEdit(member: any, index:number) {
    this.dialogService.addDialog(MeetComissionMemberForm, {
      member: member
    })
      .subscribe((res) => {
        member = res;
        if(res!=null) {
          this.meetsService.saveMeetComissionMember(member, this.meetId)
            .subscribe(
              data => {
                if (data.status == 200) {
                  if (index >= 0) {
                    this.members[index] = member;
                  }
                  else {
                    this.members.push(member);
                    this.onRefreshList();
                  }
                }
              }
            )
        }
      })
  }

  onDeleteMember(index: number) {
    if(confirm(this._translate.instant("meet.comission.member.confirmDelete"))) {
      let toDelete = this.members[index].o_id;
      this.meetsService.deleteComissionMember(toDelete)
        .subscribe(
          data => {
            if(data.status == 200) {
              this.onRefreshList();
            }
          }
        )
    }
  }
}
