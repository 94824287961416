import {Component, Input, OnInit} from "@angular/core";
import {SortRegistrationReport} from "../../../_directives/sortRegistrationReport.pipe";
import {toInteger} from "@ng-bootstrap/ng-bootstrap/util/util";

@Component({
  selector: "meetReport",
  templateUrl: "./meetReport-canvas.component.html"
})
export class MeetReportCanvas implements OnInit {
  reportData: any;

  constructor() {

  }
  ngOnInit(): void {

  }

  @Input() set data(value) {
    this.reportData=value;
  }

  decision(question) {
    if(question.questionType==2)
      return true;
    let maxAnswer=0;
    let crtAnsId=0;
    let votesTotal=0;
    for(let i=0;i<question.answers.length;i++) {
      let crtAnswer=question.answers[i];
      if(crtAnswer.result>maxAnswer) {
        maxAnswer=crtAnswer.result;
        crtAnsId=crtAnswer.answerId;
      }
      votesTotal += crtAnswer.result;
    }
    if(question.resultingAnswerCondition==3) {
      return (crtAnsId == question.resultingAnswer &&
        maxAnswer > this.reportData.totalSharesCount/2);
    } else if(question.resultingAnswerCondition==2) {
      return (crtAnsId == question.resultingAnswer &&
        maxAnswer > (votesTotal/4*3));
    } else if(question.resultingAnswerCondition == 1) {
      return crtAnsId==question.resultingAnswer;
    }
    // return crtAnsId==question.resultingAnswer?'1':"2";
  }

  sharesCount(reg, filter) {
    let ret=0;
    for(let i=0;i<reg.shares.length;i++) {
      let vote=reg.shares[i];
      if(filter==0)
        ret=ret+vote.shares;
      else if(filter==1 && reg.code==vote.holder.code)
        ret=ret+vote.shares;
      else if(filter==2 && reg.code!=vote.holder.code)
        ret=ret+vote.shares;
    }
    return ret;
  }

  sharesFilter(reg, filter) {
    let ret=[];
    for(let i=0;i<reg.shares.length;i++) {
      let vote=reg.shares[i];
      if(filter==0)
        ret.push(vote);
      else if(filter==1 && reg.code==vote.holder.code)
        ret.push(vote);
      else if(filter==2 && reg.code!=vote.holder.code)
        ret.push(vote);
    }
    return ret;
  }

  questNo(add: number, question: any) {
    return question.protocolNo;
    // return parseInt(`${question.questionNo}`, 10) + add;
    // return toInteger(question.questionNo)+add;
  }

  notVoted(question) {
    let ret=this.reportData.registrationInfo.sharesCount;
    for(let i=0;i<question.answers.length;i++)
      ret=ret-question.answers[i].result;

    return ret;
  }
}
