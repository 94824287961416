import {Injectable} from "@angular/core";
import {Http, RequestOptions, Headers, Response } from "@angular/http";
import {User} from "../_models/User";
import {AppService} from "./app.service";
import 'rxjs/add/operator/map';

@Injectable()
export class UserService {
  private url;

  constructor(appService: AppService,
              private http: Http) {
    this.url=appService.getUrl();
  }

  getAll() {
    return this.http.get(this.url+'/api/users', this.jwt()).map((response: Response) => response.json());
  }

  getById(id: number) {
    return this.http.get(this.url+'/api/users' + id, this.jwt()).map((response: Response) => response.json());
  }

  create(user: User) {
    return this.http.post(this.url+'/api/users', user, this.jwt()).map((response: Response) => response.json());
  }

  update(user: User) {
    return this.http.put(this.url+'/api/users' + user.id, user, this.jwt()).map((response: Response) => response.json());
  }

  delete(user:User) {
    return this.http.delete(this.url+'/api/users' + user.id, this.jwt()).map((response: Response) => response.json());
  }

  private jwt() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(currentUser && currentUser.token) {
      let headers = new Headers({ 'Authentication': 'Bearer ' + currentUser.token });
      return new RequestOptions({ headers: headers});
    }
  }
}
