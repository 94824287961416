import {Inject, Injectable} from "@angular/core";
import {Api} from "./PostRequest";
import {Http} from '@angular/http';
import {RequestMethod, RequestOptions, Request, Headers, Response} from "@angular/http";
import {Company} from "../pages/companies/company.class";
import {DOCUMENT} from "@angular/platform-browser";
import {AppService} from "./app.service";
import {Observable} from "rxjs/Observable";
import 'rxjs/add/operator/map';

@Injectable()
export class MeetsService {
  private url;
  constructor(appService: AppService,
    private api: Api) {
    this.url=appService.getUrl();
  }

  getMeetsByCompany(company: Company) {
    let data=company;
    return this.api.DoRequest(this.url+'/api/meets/list', RequestMethod.Post, JSON.stringify(data))
      .map((response: Response) => response.json());
  }

  loadMeetInfo(what: string, meetId: number) {
    return this.api.DoRequest(this.url+'/api/meets/getInfo/'+meetId+"/"+what, RequestMethod.Post)
      .map((response: Response) => response.json());
  }

  saveGeneralInfo(dataToSave: any, meetId: number) {
    let data=dataToSave;
    return this.api.DoRequest(this.url+'/api/meets/save/'+meetId+"/generalInfo", RequestMethod.Post, JSON.stringify(data))
      .map((response: Response) => response.json());
  }


  saveMeetComissionMember(member: any, meetId: number) {
    let data=member;
    return this.api.DoRequest(this.url+'/api/meets/save/'+meetId+'/comissionMember', RequestMethod.Post, JSON.stringify(data))
      .map((response: Response) => response.json());
  }

  deleteComissionMember(toDelete: any) {
    let data= toDelete;
    return this.api.DoRequest(this.url+'/api/meets/comissionMember/delete/'+data, RequestMethod.Delete, data)
      .map((response: Response) => response.json());
  }

  saveMeetQuestion(question: any, meetId: number) {
    let data=question;
    return this.api.DoRequest(this.url+'/api/meets/save/'+meetId+'/question', RequestMethod.Post, JSON.stringify(data))
      .map((response: Response) => response.json());
  }

  deleteQuestion(toDelete: any) {
    let data= toDelete;
    return this.api.DoRequest(this.url+'/api/meets/question/delete/'+data, RequestMethod.Delete, data)
      .map((response: Response) => response.json());
  }

  saveMeetHolder(holder: any, meetId: number) {
    let data=holder;
    return this.api.DoRequest(this.url+'/api/meets/save/'+meetId+'/holder', RequestMethod.Post, JSON.stringify(data))
      .map((response: Response) => response.json());
  }

  deleteMeetHolder(itemToDelete: any) {
    let data= itemToDelete.o_id;
    return this.api.DoRequest(this.url+'/api/meets/holder/delete/'+data, RequestMethod.Delete, data)
      .map((response: Response) => response.json());
  }

  findHolders(find: string, meetId: number, company: number) {
    let data={ company: company, find: find };
    return this.api.DoRequest(this.url+'/api/meets/registration/'+meetId+'/findShareholders', RequestMethod.Post, JSON.stringify(data))
      .map((response: Response) => response.json());
  }

  registerParticipant(save: any, meetId: number) {
    let data=save;
    return this.api.DoRequest(this.url+'/api/meets/registration/'+meetId+'/register', RequestMethod.Post, JSON.stringify(data))
      .map((response: Response) => response.json());
  }

  loadRegistration(meetId: number) {
    return this.api.DoRequest(this.url+'/api/meets/registration/'+meetId+'/getRegistration', RequestMethod.Get)
      .map((response: Response) => response.json());
  }

  cancelRegistration(crtReg: any, meetId: number) {
    let data=crtReg;
    return this.api.DoRequest(this.url+'/api/meets/registration/'+meetId+'/deleteRegistration', RequestMethod.Delete, JSON.stringify(data))
      .map((response: Response) => response.json());
  }

  loadBulletins(load: { meetId: number; registrationId: number }) {
    return this.api.DoRequest(this.url+'/api/meets/registration/'+load.meetId+'/loadBulletins', RequestMethod.Post, JSON.stringify(load))
      .map((response: Response) => response.json());
  }

  getRegistrationInfo(meetId: number) {
    return this.api.DoRequest(this.url+'/api/meets/registration/'+meetId+'/getInfo', RequestMethod.Get)
      .map((response: Response) => response.json());
  }


  findVoteQr(findQuery: any, meetId: number) {
    return this.api.DoRequest(this.url+'/api/meets/voting/'+meetId+'/qrFind', RequestMethod.Post, JSON.stringify(findQuery))
      .map((response: Response) => response.json());
  }

  addVote(result: any, meetId: number) {
    return this.api.DoRequest(this.url+'/api/meets/voting/'+meetId+'/addVote', RequestMethod.Post, JSON.stringify(result))
      .map((response: Response) => response.json());
  }

  getCurrentMeetResult(meetId: number) {
    return this.api.DoRequest(this.url+'/api/meets/voting/'+meetId+'/getCurrentResult', RequestMethod.Get)
      .map((response: Response) => response.json());
  }

  getReportsList(meetId: number) {
    return this.api.DoRequest(this.url+'/api/meets/reports/'+meetId+'/getReportsList', RequestMethod.Get)
      .map((response: Response) => response.json());
  }

  getReport(meetId, reports: any[]) {
    return this.api.DoRequest(this.url+'/api/meets/reports/'+meetId+'/generateReports', RequestMethod.Post, JSON.stringify(reports))
      .map((response: Response) => response.json());
  }

  uploadShareholdersImport(file: File, meetId: number) {
    let formData:FormData = new FormData();
    formData.append('file', file, file.name);
    let headers = new Headers();
    // headers.append('Content-Type', 'multipart/form-data');
    // headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });

    return this.api.DoUpload(this.url+'/api/meets/holders/'+meetId+'/uploadHolders', formData, options)
      .map(res => res.json());
  }

  saveShareholdersImport(meetId: number, holders: any[]) {
    return this.api.DoRequest(this.url+'/api/meets/holders/'+meetId+'/saveUploadHolders', RequestMethod.Post, JSON.stringify(holders))
      .map(res => res.json());
  }

  loadHolderAddresses(holder: any) {
    return this.api.DoRequest(this.url+'/api/meets/holders/'+holder.o_id+'/getHolderAddresses', RequestMethod.Get)
      .map(res => res.json());
  }

  cleanMeet(meetId: number) {
    return this.api.DoRequest(this.url+'/api/meets/cleanMeet/'+meetId, RequestMethod.Post)
      .map((response: Response) => response.json());
  }
}
